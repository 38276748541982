import { useEffect, useRef, useState } from "react";
import { DealRoomService } from "@sage/services";
import {
	Bullet,
	Col,
	Collapsible,
	DateHeader,
	DayHeader,
	DowHeader,
	FaIcon,
	HidePrint,
	LoadingBubble,
	MonthHeader,
	P,
	Row,
	SectionTitle,
	Tag
} from "@sage/shared/core";
import { isNullOrUndefined } from "@sage/utils";
import "./Meeting.scss";

export function Meeting({ meeting, loadMeetings, deleteMeeting }) {
	const [expanded, setExpanded] = useState<boolean>(false);
	const [generating, setGenerating] = useState<boolean>(false);
	const [transcribing, setTranscribing] = useState<boolean>(false);
	const activeRef = useRef(null);

	async function checkMeetingStatus() {
		if (isNullOrUndefined(meeting.meeting_name)) {
			const response = await DealRoomService.CheckMeetingStatus(meeting.meeting_id);
			if (response.statusCode !== 200) {
				await DealRoomService.StartMeetingTranscribe(`${meeting.meeting_id}.${meeting.format}`);
				setTimeout(async () => {
					if (activeRef.current) {
						await checkMeetingStatus();
					}
				}, 5000);
			} else {
				if (response.payload.TranscriptionJob.TranscriptionJobStatus === "COMPLETED") {
					setTranscribing(false);
					setGenerating(true);
					const result = await DealRoomService.ExtractMeetingDetails(meeting.meeting_id);
					await DealRoomService.SaveMeeting({ ...meeting, ...result });
					await loadMeetings();
					setGenerating(false);
				} else {
					setTranscribing(true);
					setTimeout(async () => {
						if (activeRef.current) {
							await checkMeetingStatus();
						}
					}, 5000);
				}
			}
		}
	}

	async function getTranscript() {}

	useEffect(() => {
		checkMeetingStatus();
	}, [meeting.meeting_id]);

	useEffect(() => {
		activeRef.current = true;
		return () => {
			activeRef.current = false;
		};
	}, []);

	return (
		<div className="__sage-dealroom-meeting">
			<Col gap={"1rem"}>
				<Row
					verticalAlign="top"
					gap="1rem"
					wrap={false}
				>
					<div className="date-section">
						<Col
							gap={"0rem"}
							horizontalAlign="center"
						>
							<DowHeader
								size="lg"
								light
								color={"#083f61"}
							>
								{meeting.createdTimestamp}
							</DowHeader>
							<DayHeader
								size="xl"
								light
								color={"#083f61"}
							>
								{meeting.createdTimestamp}
							</DayHeader>
							<MonthHeader
								size="md"
								color={"#083f61"}
							>
								{meeting.createdTimestamp}
							</MonthHeader>
						</Col>
					</div>
					{transcribing && (
						<div className="meeting-loading">
							<div className="title">Generating Transcript</div>
							<LoadingBubble />
						</div>
					)}
					{generating && (
						<div className="meeting-loading">
							<div className="title">Generating Meeting Details</div>
							<LoadingBubble />
						</div>
					)}
					<Col>
						<SectionTitle>{meeting.meeting_name}</SectionTitle>
						<P>{meeting.meeting_overview}</P>
					</Col>
				</Row>
				<Collapsible
					visible={expanded}
					printable
					allowOverflow
				>
					<Col>
						<div className="section-title">Meeting Notes</div>
						<div className="notes">
							<P>{meeting.meeting_notes}</P>
						</div>
						<div className="section-title">Attendees</div>
						<div className="attendees">
							{meeting.attendees.map((attendee) => (
								<div
									className="attendee"
									key={attendee.name}
								>
									<Col gap="0rem">
										<Row>
											<Tag color={"#083f61"}>{attendee.name}</Tag>
											<Bullet />
											<Tag>{attendee.role}</Tag>
										</Row>
										<P>{attendee.summary}</P>
									</Col>
								</div>
							))}
						</div>
						<div className="section-title">Next Steps</div>
						<div className="tasks">
							{meeting.tasks.map((task) => (
								<div className="task">
									<Col gap="0rem">
										<Row verticalAlign="center">
											<Tag color={"#083f61"}>{task.task_name}</Tag>
											{task.due_date && <Bullet />}
											{task.due_date && <DateHeader size="sm">{task.due_date}</DateHeader>}
										</Row>
										<P>{task.description}</P>
										{task.task_owners.map((owner) => (
											<div className="task-owner">
												<Row verticalAlign="center">
													<Tag>{owner.name}</Tag>
													<Bullet />
													<P>{owner.responsibility}</P>
												</Row>
											</div>
										))}
									</Col>
								</div>
							))}
						</div>
					</Col>
				</Collapsible>
				<HidePrint>
					<Row
						horizontalAlign="between"
						verticalAlign="center"
					>
						<Row verticalAlign="center">
							{meeting.meeting_name && (
								<FaIcon
									icon={expanded ? "compress" : "expand"}
									color={"#00435c"}
									size={"lg"}
									onClick={() => setExpanded((e) => !e)}
								></FaIcon>
							)}
						</Row>
						<Row>
							<FaIcon
								icon={"trash-can"}
								color={"#a80300"}
								animation={"fa-shake"}
								size={"lg"}
								onClick={deleteMeeting}
							></FaIcon>
						</Row>
					</Row>
				</HidePrint>
			</Col>
		</div>
	);
}
