import { IDealRoomProjectPageTemplate } from "@sage/types";
import { HttpService } from "../../HttpService";

export function GetPageTemplates(): Promise<IDealRoomProjectPageTemplate[]> {
	return HttpService.Get<IDealRoomProjectPageTemplate[]>({ path: "/dealrooms/page-templates/team" }).then((res) => res.payload);
}

export function DeletePageTemplate(page_template_id: string) {
	return HttpService.Delete({ path: `/dealrooms/page-templates/${page_template_id}` });
}

export function SavePageTemplate(body: Partial<IDealRoomProjectPageTemplate>): Promise<IDealRoomProjectPageTemplate> {
	return HttpService.Post<IDealRoomProjectPageTemplate>({ path: "/dealrooms/page-templates", body }).then((res) => res.payload);
}
