import { DealRoomProjectElementType } from "@sage/types";
import { dealRoomProjectElementIcon, useDealRoomProject } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { FaIcon, RibonMenu, RibonSection } from "@sage/shared/core";
import { useStages } from "@sage/utils";

export function NarrativeSection({ title, id }) {
	const { addElement } = useDealRoomProject();

	async function addSageElement(element_type, element_id: string) {
		addElement(element_type, element_id);
		await DealRoomService.EmitSageTemplateUse(element_id);
	}

	return (
		<RibonSection
			title={title}
			id={id}
		>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "GLNZNAKQNSRQHJVY"
									})
								);
							}}
						>
							Company History
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "DVKLLHWELVXCGREW"
									})
								);
							}}
						>
							Mission and Values
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "XYLZCHGFTEWAJUMN"
									})
								);
							}}
						>
							Business Model
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "KUZYRUSTMNMWFGWC"
									})
								);
							}}
						>
							Key Milestones
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "MJHXNDNLPWKMFDWB"
									})
								);
							}}
						>
							Company Origins
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "PFKJFKMDXQRQDFQB"
									})
								);
							}}
						>
							Company Ownership
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "CKFGKHKBZLYYBGGU"
									})
								);
							}}
						>
							Core Business
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "WUDESMRLGDNVLQNC"
									})
								);
							}}
						>
							Geographic Coverage
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"briefcase"}
					size={"lg"}
					color={"#4d4d4d"}
					secondaryColor={"#4d4d4d"}
					secondaryOpacity={"0.9"}
					direction="row"
					fontSize="0.85rem"
					gap="0.25rem"
				>
					Company Overview
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "VLSLLVHYBKAZTZUW"
									})
								);
							}}
						>
							Industry Landscape
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "PTFPEQXDHNKJGNCE"
									})
								);
							}}
						>
							Industry Dominance
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "PDJAXBSCRJNKRDYM"
									})
								);
							}}
						>
							Historical Trends
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "GWPSGKLAUTZUTTYU"
									})
								);
							}}
						>
							Growth Potential
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "WQFTLDCPSXCMRTVP"
									})
								);
							}}
						>
							Future Outlook
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "ZEXGYUBADDPUYWDB"
									})
								);
							}}
						>
							Regulatory Environment
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"industry"}
					size={"lg"}
					color={"#4d4d4d"}
					secondaryColor={"#797f81"}
					secondaryOpacity={"0.8"}
					direction="row"
					fontSize="0.85rem"
					gap="0.25rem"
				>
					Industry
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "NBKQQFKMWRJDNLZD"
									})
								);
							}}
						>
							Strategic Partnerships
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "QRBXPZLPAYDGYACP"
									})
								);
							}}
						>
							Growth Strategy
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "RERVAVPQPVMEHNYE"
									})
								);
							}}
						>
							Entry Approach
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "RHQLLJPXKFQYSJPW"
									})
								);
							}}
						>
							Strategic Plan
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "NRCSJDWQFBTPEYLJ"
									})
								);
							}}
						>
							Strategic Initiatives
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "VRDVUBBCSCFSEFQY"
									})
								);
							}}
						>
							Sales Enhancements
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "BHDAGCTMUPLVZRBJ"
									})
								);
							}}
						>
							Market Opportunities
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"chart-line-up"}
					size={"lg"}
					color={"#0051a8"}
					secondaryColor={"#4d4d4d"}
					secondaryOpacity={"0.9"}
					direction="row"
					fontSize="0.85rem"
					gap="0.25rem"
				>
					Growth
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "BUSBKTYTGFSUTCJM"
									})
								);
							}}
						>
							Strategic Overview
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "AFWQXAYMNZECUMTT"
									})
								);
							}}
						>
							Detailed Description
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "KKUEUCEBUPLKLQMY"
									})
								);
							}}
						>
							Future Roadmap
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "VQAJTBGXCPTLKADF"
									})
								);
							}}
						>
							R&D Process
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "XANKAJCYGKFBNTPH"
									})
								);
							}}
						>
							Customer Feedback
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"boxes-stacked"}
					size={"lg"}
					color={"#d29c6b"}
					secondaryColor={"#dad3cb"}
					direction="row"
					fontSize="0.85rem"
					gap="0.25rem"
				>
					Products & Services
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "NAVQJQQDHTEEXPTM"
									})
								);
							}}
						>
							Operational Infrastructure
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "WJPUPURMYREUKQRR"
									})
								);
							}}
						>
							Workflow Description
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "KHNZSJKUDHFEQYAT"
									})
								);
							}}
						>
							Quality Controls
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "KJYXSGTJKJMTVGSN"
									})
								);
							}}
						>
							Supply Chain Overview
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "DRRPWTSRDAFBHQCH"
									})
								);
							}}
						>
							Facility Overview
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "AZNJFQSQFSKJJDME"
									})
								);
							}}
						>
							Facility Operational Capacity
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "UCHMETBHHEGEDYKV"
									})
								);
							}}
						>
							Operating Model
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"network-wired"}
					size={"lg"}
					color={"#0063b1"}
					secondaryColor="#000000"
					direction="row"
					fontSize="0.85rem"
					gap="0.25rem"
				>
					Operations
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "ZSBKWZKRCUFUHZAE"
									})
								);
							}}
						>
							Leadership Overview
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "PHCGKSKFSFYKQQVU"
									})
								);
							}}
						>
							Experience Summary
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "HADZUSZGNDSVVAMZ"
									})
								);
							}}
						>
							Organizational Structure
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "DTBCSCAHVSSWGAZG"
									})
								);
							}}
						>
							Sales Team Overview
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "MDPUYGGVDQKRZFLS"
									})
								);
							}}
						>
							Customer Relationships
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"user-tie"}
					size={"lg"}
					color={"#4d4d4d"}
					secondaryColor={"#daac9e"}
					secondaryOpacity="0.9"
					direction="row"
					fontSize="0.85rem"
					gap="0.25rem"
				>
					Management
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "JYFZQKBNLPSSTTJU"
									})
								);
							}}
						>
							Income Streams
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "SMHTHCLPQLTZXDSX"
									})
								);
							}}
						>
							Pricing Strategy
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "KNRHQJCMRNEMRUAD"
									})
								);
							}}
						>
							Growth Drivers
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "WUDPHRWXYNXSZLKY"
									})
								);
							}}
						>
							Operating Model
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "QYTNFDBFTRPGGUEW"
									})
								);
							}}
						>
							Growth Potential
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "LAVCQKGQMDVXFNBL"
									})
								);
							}}
						>
							Market Expansion
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "TPWJSPUMPCQJSWTG"
									})
								);
							}}
						>
							Resource Requirements
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"arrow-progress"}
					size={"lg"}
					color={"#0051a8"}
					secondaryColor={"#4d4d4d"}
					secondaryOpacity="0.9"
					direction="row"
					fontSize="0.85rem"
					gap="0.25rem"
				>
					Business Model
				</FaIcon>
			</RibonMenu>
		</RibonSection>
	);
}
