import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { IChronosEvent } from "@sage/types";
import { ChronosService } from "@sage/services";
import { Button, Col, Collapsible, H1, LoadingBubble, P } from "@sage/shared/core";
import { Form, FormFieldVariant } from "@sage/shared/forms";
import { isNullOrUndefined, useDebounce } from "@sage/utils";
import "./Chronos.scss";

export function Chronos() {
	const form = useForm();
	const user_id = useWatch({ control: form.control, name: "user_id" });
	const [userRequests, setUserRequests] = useState<IChronosEvent[]>([]);
	const [chronosEvents, setChronosEvents] = useState<IChronosEvent[]>([]);
	const [metadata, setMetadata] = useState(null);
	const [activeRequest, setActiveRequest] = useState(null);
	const [activeEvent, setActiveEvent] = useState(null);
	const [results, setResults] = useState([]);
	const [cols, setCols] = useState([]);
	const [loading, setLoading] = useState(false);
	const [failed, setFailed] = useState(false);

	async function loadUserRequests(user_id: string) {
		const user_requests = await ChronosService.ListUserEvents(user_id);
		if (user_requests) {
			setUserRequests(user_requests);
		}
	}

	async function loadRequest(request_id: string) {
		setActiveRequest(request_id);
		const chronos_events = await ChronosService.GetRequestEvents(request_id);
		if (chronos_events) {
			setChronosEvents(chronos_events);
		}
	}

	const loadUserRequestsDebounce = useDebounce(loadUserRequests, 1000);

	useEffect(() => {
		if (user_id) {
			loadUserRequestsDebounce(user_id);
		}
	}, [user_id]);

	async function loadSql({ query }) {
		console.log(query);
		setLoading(true);
		const _results = await ChronosService.Sql(query);
		if (_results && _results.length > 0) {
			setCols(Object.keys(_results[0]));
			setResults(_results);
			setFailed(false);
		} else {
			setResults([]);
			setCols([]);
			setFailed(true);
		}
		setLoading(false);
	}

	return (
		<div className="__sage-chronos-container">
			<Col>
				<H1>Chronos</H1>
				<Form
					fill
					fields={[
						{
							variant: FormFieldVariant.Vertical,
							control: form.control,
							name: "query",
							placeholder: "query",
							type: "textarea",
							rows: 20
						}
					]}
				/>
				<Button action={form.handleSubmit(loadSql)}>Run</Button>
				{loading && <LoadingBubble />}
				{failed && <P>No data</P>}
				<div className="__sage-chronos-data-table-container">
					<table className="__sage-chronos-data-table">
						<thead>
							{cols.map((col) => (
								<th key={col}>{col}</th>
							))}
						</thead>
						<tbody>
							{results.map((result, idx) => (
								<tr key={`sql-${idx}`}>
									{cols.map((col) => (
										<td key={`row-${idx}-${col}`}>{JSON.stringify(result[col], null, 2)}</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</Col>
		</div>
	);

	return (
		<div className="__sage-chronos-container">
			<H1>Chronos</H1>
			<Form
				fill
				fields={[
					{
						variant: FormFieldVariant.Vertical,
						control: form.control,
						name: "user_id",
						placeholder: "user_id"
					}
				]}
			/>
			<div className="__sage-chronos-data-table-container">
				{chronosEvents?.length > 0 && (
					<table className="__sage-chronos-data-table">
						<thead>
							<th>event_id</th>
							<th>service</th>
							<th>operation</th>
							<th>path</th>
							<th>message</th>
							<th>user_id</th>
							<th>team_code</th>
							<th>timestamp</th>
						</thead>
						<tbody>
							{chronosEvents.map((req) => (
								<tr
									onClick={() => {
										setMetadata(req.metadata);
										setActiveEvent(req.event_id);
									}}
									className={activeEvent === req.event_id ? "active" : ""}
								>
									<td>{req.event_id}</td>
									<td>{req.service || "None"}</td>
									<td>{req.operation || "None"}</td>
									<td>{req.path || "None"}</td>
									<td>{req.message || "None"}</td>
									<td>{req.user_id || "None"}</td>
									<td>{req.team_code || "None"}</td>
									<td>
										{new Date(req.timestamp).toLocaleDateString("en-US", {
											year: "numeric",
											month: "numeric",
											day: "numeric",
											hour: "numeric",
											minute: "numeric"
										})}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>
			<Collapsible visible={!isNullOrUndefined(metadata)}>
				<div className="metadata">{JSON.stringify(metadata, null, 2)}</div>
			</Collapsible>
			<div className="__sage-chronos-data-table-container">
				{userRequests?.length > 0 && (
					<table className="__sage-chronos-data-table">
						<thead>
							<th>user_id</th>
							<th>team_code</th>
							<th>request_id</th>
							<th>timestamp</th>
						</thead>
						<tbody>
							{userRequests.map((req) => (
								<tr
									onClick={() => loadRequest(req.request_id)}
									className={activeRequest === req.request_id ? "active" : ""}
								>
									<td>{req.user_id || "None"}</td>
									<td>{req.team_code || "None"}</td>
									<td>{req.request_id}</td>
									<td>
										{new Date(req.timestamp).toLocaleDateString("en-US", {
											year: "numeric",
											month: "numeric",
											day: "numeric",
											hour: "numeric",
											minute: "numeric"
										})}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>
		</div>
	);
}
