import React from "react";
import { useNavigate } from "react-router-dom";
import { DealRoomProjectElementType } from "@sage/types";
import { useDealRoom } from "@sage/state";
import { Dropdown, DropdownOption, FaIcon, Link, Row } from "@sage/shared/core";
import { DealTimeline } from "../DealTimeline";
import "./Header.scss";

export function Header() {
	const {
		deal_name,
		setUploadModalVisible,
		setCreateElement,
		setBrowseTemplateModalVisible,
		setViewDealSources,
		updateDealName,
		setAddWebDataModalVisible,
		setViewDealWebData,
		setBrowsePagesModalVisible,
		phase
	} = useDealRoom();

	const navigate = useNavigate();

	return (
		<div
			className={`__sage-deal-room-header`}
			style={{ borderBottom: `solid 5px ${phase?.phase_color}` }}
		>
			<Row
				verticalAlign="center"
				paddingInline="0.25rem"
				horizontalAlign="between"
			>
				<Row verticalAlign="center">
					<Link to={"/app"}>
						<FaIcon
							size="xl"
							icon="arrow-turn-left"
							color="#2965c6"
							secondaryOpacity="0.75"
							paddingInline="0"
							padding="0.25rem"
						/>
					</Link>
					<input
						className="deal-room-name"
						value={deal_name}
						onChange={(e) => updateDealName(e.target.value)}
						style={{ width: `${Math.max(deal_name?.length * 1.3, 12)}ch` }}
					/>
				</Row>
				<Row verticalAlign="center">
					<Dropdown
						title={"Deal"}
						direction="left"
					>
						<DropdownOption>
							<FaIcon
								icon={"book-atlas"}
								size="2x"
								color={"#5700a8"}
								direction={"row"}
								onClick={() => setViewDealSources(true)}
							>
								Deal Sources
							</FaIcon>
						</DropdownOption>
						<DropdownOption>
							<FaIcon
								icon={"globe-pointer"}
								size="2x"
								color={"#5700a8"}
								direction={"row"}
								onClick={() => setViewDealWebData(true)}
							>
								Web Data
							</FaIcon>
						</DropdownOption>
					</Dropdown>
					<Dropdown
						title={"Add Data"}
						direction="left"
					>
						<DropdownOption>
							<FaIcon
								icon={"folder-upload"}
								size="2x"
								color={"#f7a501"}
								direction={"row"}
								onClick={() => setUploadModalVisible(true)}
							>
								Upload Files
							</FaIcon>
						</DropdownOption>
						{/*<DropdownOption>
							<FaIcon
								icon={"file-spreadsheet"}
								size="2x"
								color={"#00a857"}
								direction={"row"}
								tooltip={"Coming Soon"}
							>
								Upload Financials
							</FaIcon>
						</DropdownOption>*/}
						<DropdownOption>
							<FaIcon
								icon={"globe-pointer"}
								size="2x"
								color={"#5700a8"}
								direction={"row"}
								onClick={() => setAddWebDataModalVisible(true)}
							>
								Add Web Data
							</FaIcon>
						</DropdownOption>
					</Dropdown>
					<Dropdown
						title={"Elements"}
						direction="left"
					>
						<DropdownOption>
							<FaIcon
								icon={"chart-pie-simple-circle-dollar"}
								size="2x"
								color={"#0051a8"}
								direction={"row"}
								onClick={() => setCreateElement(DealRoomProjectElementType.Chart)}
							>
								Chart
							</FaIcon>
						</DropdownOption>
						<DropdownOption>
							<FaIcon
								icon={"file-spreadsheet"}
								size="2x"
								color={"#00a857"}
								direction={"row"}
								onClick={() => setCreateElement(DealRoomProjectElementType.Table)}
							>
								Table
							</FaIcon>
						</DropdownOption>
						<DropdownOption>
							<FaIcon
								icon={"align-left"}
								size="2x"
								color={"#a80300"}
								direction={"row"}
								onClick={() => setCreateElement(DealRoomProjectElementType.Paragraph)}
							>
								Paragraph
							</FaIcon>
						</DropdownOption>
						<DropdownOption>
							<FaIcon
								icon={"list-ul"}
								size="2x"
								color={"#30abc6"}
								direction={"row"}
								onClick={() => setCreateElement(DealRoomProjectElementType.Bullets)}
							>
								Bullets
							</FaIcon>
						</DropdownOption>
						<DropdownOption>
							<FaIcon
								icon={"books"}
								size="2x"
								color={"#2965c6"}
								direction={"row"}
								onClick={() => setBrowseTemplateModalVisible(true)}
							>
								Browse Elements
							</FaIcon>
						</DropdownOption>
						<DropdownOption>
							<FaIcon
								icon={"layer-group"}
								size="2x"
								color={"#2965c6"}
								direction={"row"}
								onClick={() => setBrowsePagesModalVisible(true)}
							>
								Browse Pages
							</FaIcon>
						</DropdownOption>
					</Dropdown>
					<Dropdown
						title={"Meetings"}
						direction="left"
					>
						<DropdownOption>
							<FaIcon
								icon={"users"}
								size="2x"
								color={"#5700a8"}
								direction={"row"}
								onClick={() => navigate("./meetings")}
							>
								Open Meetings
							</FaIcon>
						</DropdownOption>
					</Dropdown>
				</Row>
			</Row>
			<DealTimeline />
		</div>
	);
}
