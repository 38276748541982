import React from "react";
import { Link } from "react-router-dom";
import { useDealRoom } from "@sage/state";
import { Col, FaIcon, HidePrint, Row } from "@sage/shared/core";
import { usePageMeta } from "@sage/utils";
import { UploadFilesModal } from "../../DealRoomHome/UploadFilesModal";
import "./Header.scss";

export function Header() {
	const { deal_name } = useDealRoom();
	usePageMeta({ title: `${deal_name} | Meetings` });

	return (
		<HidePrint>
			<div className="__sage-deal-room-meetings-header">
				<Col
					height="100%"
					gap="0"
					verticalAlign="bottom"
					flex={"1"}
				>
					<Row
						horizontalAlign="between"
						verticalAlign="center"
					>
						<Row verticalAlign="center">
							<Link to={"../"}>
								<FaIcon
									size="xl"
									icon="arrow-turn-left"
									color="#2965c6"
									secondaryOpacity="0.75"
									paddingInline="0"
									padding="0.25rem"
								/>
							</Link>
							<div className="meeting-name">{deal_name}</div>
						</Row>
						<Row
							horizontalAlign="right"
							verticalAlign="center"
						>
							<FaIcon
								icon={"print"}
								color={"#00435c"}
								size={"lg"}
								onClick={() => window.print()}
							>
								Export to PDF
							</FaIcon>
						</Row>
					</Row>
				</Col>
				<UploadFilesModal />
			</div>
		</HidePrint>
	);
}
