import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import { IDealRoomElementTemplate, IDealRoomProjectPageTemplate } from "@sage/types";
import { useDealRoom } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { ButtonBorderShape, ButtonVariant, Col, DeleteConfirmation, LoadingBubble, Modal, Pad, Row, SectionTitle } from "@sage/shared/core";
import { PageTemplate, TemplateListItem } from "@sage/shared/dealrooms";
import { FormField, FormFieldVariant } from "@sage/shared/forms";
import { isNullOrUndefined, useDebounce } from "@sage/utils";
import "./BrowsePagesModal.scss";

export function BrowsePagesModal() {
	const { browsePagesModalVisible, setBrowsePagesModalVisible } = useDealRoom();
	const [searchTerm, setSearchTerm] = useState<string>(null);
	const [pageTemplates, setPageTemplates] = useState<IDealRoomProjectPageTemplate[]>([]);
	const [allPageTemplates, setAllPageTemplates] = useState<IDealRoomProjectPageTemplate[]>([]);
	const [deletePage, setDeletePage] = useState<IDealRoomProjectPageTemplate>(null);
	const [editPage, setEditPage] = useState<IDealRoomProjectPageTemplate>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [templates, setTemplates] = useState<IDealRoomElementTemplate[]>([]);
	const [allTemplates, setAllTemplates] = useState<IDealRoomElementTemplate[]>([]);
	const editForm = useForm();
	const editPageName = useWatch({ control: editForm.control, name: "page_name" });
	const editFormElements = useFieldArray({ control: editForm.control, name: "elements" });
	const form = useForm();

	async function loadPageTemplates() {
		const _page_templates = await DealRoomService.GetPageTemplates();
		setPageTemplates(_page_templates);
		setAllPageTemplates(_page_templates);
	}

	function search(term: string) {
		setSearchTerm(term);
		setPageTemplates(
			allPageTemplates.filter((t) => {
				if (
					t.page_name.toLowerCase().includes(term.toLowerCase()) ||
					t.page_title?.toLowerCase().includes(term.toLowerCase()) ||
					t.page_description?.toLowerCase().includes(term.toLowerCase())
				) {
					return true;
				} else {
					return false;
				}
			})
		);
	}

	async function searchElements(term: string) {
		setLoading(true);
		setSearchTerm(term);
		const response = await DealRoomService.SearchTeamTemplates(term);
		if (response) {
			setTemplates(response);
		} else {
			setTemplates(allTemplates);
		}
		setLoading(false);
	}

	const searchDebounce = useDebounce(searchElements, 1000);

	async function loadTemplates() {
		let _templates = await DealRoomService.GetTemplateByTeam();
		const _recent_templates = await DealRoomService.GetRecentTeamElements();
		const _recent_ids = _recent_templates.map((el) => el.element_id);
		_templates = _templates.filter((el) => !_recent_ids.includes(el.element_id));
		setTemplates([..._recent_templates, ..._templates]);
		setAllTemplates([..._recent_templates, ..._templates]);
	}

	useEffect(() => {
		if (browsePagesModalVisible) {
			loadPageTemplates();
			loadTemplates();
		}
	}, [browsePagesModalVisible]);

	function dismiss() {
		setBrowsePagesModalVisible(false);
		setPageTemplates([]);
		setAllPageTemplates([]);
	}

	async function confirmDeletePage() {
		if (deletePage) {
			await DealRoomService.DeletePageTemplate(deletePage.page_id);
			setAllPageTemplates((_pages) => _pages.filter((p) => p.page_id !== deletePage.page_id));
			setPageTemplates((_pages) => _pages.filter((p) => p.page_id !== deletePage.page_id));
			setDeletePage(null);
		}
	}

	function selectPage(p) {
		setEditPage(p);
		editForm.reset(p);
	}

	async function savePage(p) {
		await DealRoomService.SavePageTemplate(p);
		setEditPage(null);
		editForm.reset({});
		loadPageTemplates();
	}

	return (
		<>
			<Modal
				visible={browsePagesModalVisible}
				onDismiss={dismiss}
			>
				<div className="__browse-pages-modal">
					<SectionTitle>Pages</SectionTitle>
					<Col>
						<FormField
							control={form.control}
							name="search"
							variant={FormFieldVariant.Vertical}
							placeholder="Search..."
							onChange={(e) => {
								search(e);
							}}
						/>
						{pageTemplates
							?.sort((a, b) => a.page_name.localeCompare(b.page_name))
							.map((page) => (
								<PageTemplate
									key={page.page_id}
									template={page}
									searchTerm={searchTerm}
									remove={() => setDeletePage(page)}
									select={() => selectPage(page)}
									useCooldown={false}
								/>
							))}
					</Col>
				</div>
			</Modal>
			<DeleteConfirmation
				visible={!isNullOrUndefined(deletePage)}
				onDismiss={() => setDeletePage(null)}
				onConfirm={confirmDeletePage}
			>
				<Pad>
					<PageTemplate template={deletePage} />
				</Pad>
			</DeleteConfirmation>
			<Modal
				visible={!isNullOrUndefined(editPage)}
				onDismiss={() => setEditPage(null)}
				actions={[
					{
						children: "Close",
						borderShape: ButtonBorderShape.Round,
						variant: ButtonVariant.Secondary,
						action: () => setEditPage(null)
					},
					{
						children: "Save",
						borderShape: ButtonBorderShape.Round,
						variant: ButtonVariant.Primary,
						action: editForm.handleSubmit(savePage)
					}
				]}
			>
				<div className="__browse-pages-modal">
					<Row
						horizontalAlign="between"
						wrap={false}
						gap={"1rem"}
					>
						<Col
							width="100%"
							scroll
							padding="0.2rem"
						>
							<SectionTitle>{editPageName}</SectionTitle>
							<FormField
								control={editForm.control}
								placeholder={"Page Name..."}
								name="page_name"
								variant={FormFieldVariant.Vertical}
								label="Page Name"
								description="This is the name of the page in your list of pages"
								fill
							/>
							<FormField
								control={editForm.control}
								placeholder={"Page Title..."}
								name="page_title"
								variant={FormFieldVariant.Vertical}
								label="Page Title"
								description="This is the title of the slide this generates"
								fill
							/>
							<FormField
								control={editForm.control}
								placeholder={"Page Description..."}
								name="page_description"
								variant={FormFieldVariant.Vertical}
								label="Page Description"
								description="A description for this page so you can identity it in your list of pages"
								type="textarea"
								fill
							/>
							{editFormElements.fields.map((field, idx) => {
								return (
									<Controller
										key={field.id}
										name={`elements.${idx}`}
										control={editForm.control}
										render={({ field }) => (
											<EditPageElement
												element_id={field.value}
												remove={() => editFormElements.remove(idx)}
												moveUp={idx > 0 ? () => editFormElements.swap(idx, idx - 1) : null}
												moveDown={
													idx < editFormElements.fields.length - 1
														? () => editFormElements.swap(idx, idx + 1)
														: null
												}
											/>
										)}
									/>
								);
							})}
						</Col>
						<Col
							width={"100%"}
							scroll
							padding={"0.25rem"}
						>
							<SectionTitle>Add Elements</SectionTitle>
							<FormField
								control={form.control}
								name="search"
								variant={FormFieldVariant.Vertical}
								placeholder="Search..."
								onChange={(e) => {
									setLoading(true);
									searchDebounce(e);
								}}
							/>
							{loading ? (
								<Row horizontalAlign="center">
									<LoadingBubble />
								</Row>
							) : (
								templates.map((template) => (
									<TemplateListItem
										key={template.element_id}
										template={template}
										select={() => editFormElements.append(template.element_id)}
										searchTerm={searchTerm}
									/>
								))
							)}
						</Col>
					</Row>
				</div>
			</Modal>
		</>
	);
}

function EditPageElement({ element_id, remove, moveUp, moveDown }) {
	const [el, setEl] = useState<IDealRoomElementTemplate>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const { setUpdateElement } = useDealRoom();

	async function loadElementTemplate() {
		setLoading(true);
		const _el = await DealRoomService.GetTemplate(element_id);
		if (isNullOrUndefined(_el)) {
			remove();
		} else {
			setEl(_el);
		}
		setLoading(false);
	}

	useEffect(() => {
		if (element_id) {
			loadElementTemplate();
		}
	}, [element_id]);

	if (loading || isNullOrUndefined(el)) {
		return <LoadingBubble />;
	} else {
		return (
			<TemplateListItem
				template={el}
				select={() => setUpdateElement(el)}
				remove={remove}
				moveUp={moveUp}
				moveDown={moveDown}
			/>
		);
	}
}
