import { ReactNode, useEffect, useRef, useState } from "react";
import { FaIcon } from "../FaIcon";
import "./Popover.scss";

export function Popover({ children }: { children: ReactNode }) {
	const [open, setOpen] = useState(false);
	const elementRef = useRef(null);
	const containerRef = useRef(null);

	function adjustPopover() {
		if (elementRef.current && containerRef.current && open) {
			let el = containerRef.current;
			const elStyles = window.getComputedStyle(elementRef.current);
			const rect = elementRef.current.getBoundingClientRect();

			while (el) {
				const styles = window.getComputedStyle(el);
				const containerRect = el.getBoundingClientRect();

				const elCouldClip =
					styles.overflow === "hidden" ||
					styles.overflow === "auto" ||
					styles.overflow === "scroll" ||
					styles.overflowX === "hidden" ||
					styles.overflowY === "hidden";

				if (elCouldClip) {
					const isClipping =
						rect.top < containerRect.top ||
						rect.bottom > containerRect.bottom ||
						rect.left < containerRect.left ||
						rect.right > containerRect.right;
					if (isClipping) {
						console.group("edit");
						console.log({ el, rect, containerRect });
						let lpad = 0;
						let rpad = 0;
						let tpad = 0;
						let bpad = 0;

						let newRight = 0;
						let newLeft = 0;
						let newTop = 0;
						let newBottom = 0;

						let curRight = Number.parseInt(elStyles.right.replace("px", ""));
						let curLeft = Number.parseInt(elStyles.left.replace("px", ""));
						let curTop = Number.parseInt(elStyles.top.replace("px", ""));
						let curBottom = Number.parseInt(elStyles.bottom.replace("px", ""));

						if (rect.top < containerRect.top) {
							tpad = containerRect.top - rect.top + 36;
							newTop = curTop + tpad;
							newBottom = curBottom - tpad;
						} else if (rect.bottom > containerRect.bottom) {
							bpad = rect.bottom - containerRect.bottom;
							newBottom = curBottom + bpad;
							newTop = curTop - bpad;
						} else {
							newBottom = curBottom;
							newTop = curTop;
						}

						if (rect.left < containerRect.left) {
							lpad = containerRect.left - rect.left - 8;
							newLeft = curLeft + lpad;
							newRight = curRight - lpad;
						} else if (rect.right > containerRect.right) {
							rpad = rect.right - containerRect.right + 8;
							newRight = curRight + rpad;
							newLeft = curLeft - rpad;
						} else {
							newLeft = curLeft;
							newRight = curRight;
						}

						console.log({ tpad, curTop, newTop });
						console.log({ bpad, curBottom, newBottom });
						console.log({ lpad, curLeft, newLeft });
						console.log({ rpad, curRight, newRight });

						elementRef.current.style.top = `${newTop}px`;
						elementRef.current.style.bottom = `${newBottom}px`;
						elementRef.current.style.left = `${newLeft}px`;
						elementRef.current.style.right = `${newRight}px`;

						console.groupEnd();

						break;
					}
				}

				el = el.parentElement;
			}
		}
	}

	useEffect(() => {
		adjustPopover();
	}, [open]);

	return (
		<div
			className="__sage-popover"
			ref={containerRef}
		>
			<div
				className="popover-icon"
				onClick={() => setOpen((e) => !e)}
				onBlur={() => setOpen(false)}
				tabIndex="0"
			>
				<FaIcon
					size="md"
					color="#000000"
					icon="info"
					padding="0"
					hideBg
					secondaryOpacity="1"
					width="fit-content"
				/>
			</div>
			{open && (
				<div
					className={`inner`}
					ref={elementRef}
				>
					{children}
				</div>
			)}
		</div>
	);
}
