import { DealRoomProjectElementType } from "@sage/types";
import { dealRoomProjectElementIcon, useDealRoomProject } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { FaIcon, RibonMenu, RibonSection } from "@sage/shared/core";
import { useStages } from "@sage/utils";

export function ChartsAndGraphsSection({ title, id }) {
	const { addElement } = useDealRoomProject();

	async function addSageElement(element_type, element_id: string) {
		addElement(element_type, element_id);
		await DealRoomService.EmitSageTemplateUse(element_id);
	}

	return (
		<RibonSection
			title={title}
			id={id}
		>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={"chart-column"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "XPHKACJQQKCLDGMN"
									})
								);
							}}
						>
							Financial Performance Metrics
						</FaIcon>
						<FaIcon
							icon={"chart-column"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "JKVDRDPLQEVAPPWP"
									})
								);
							}}
						>
							Historical and Projected Income Statement
						</FaIcon>
						<FaIcon
							icon={"chart-waterfall"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "RFBMQKHDDMSXVYEV"
									})
								);
							}}
						>
							EBITDA Bridge Analysis
						</FaIcon>
						<FaIcon
							icon={"chart-column"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "FLLLFAMCZQVRTGPC"
									})
								);
							}}
						>
							Operating Expense Breakdown
						</FaIcon>
						<FaIcon
							icon={"chart-waterfall"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "XLVDBGFXFJKDPADB"
									})
								);
							}}
						>
							Operating Expense Bridge Analysis
						</FaIcon>
						<FaIcon
							icon={"chart-column"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "LUGPASPTTFWQBVLX"
									})
								);
							}}
						>
							Historical EBITDA Performance
						</FaIcon>
						<FaIcon
							icon={"chart-column"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "HZGFKJJVFWQRWSRD"
									})
								);
							}}
						>
							Margin Analysis Trends
						</FaIcon>
						<FaIcon
							icon={"chart-waterfall"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "PRDNUZAHSJSSYJXG"
									})
								);
							}}
						>
							Employee Cost Structure Analysis
						</FaIcon>
						<FaIcon
							icon={"chart-column"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "FLWGLKKPEMJURMVB"
									})
								);
							}}
						>
							Operating Expense Margin Analysis
						</FaIcon>
						<FaIcon
							icon={"chart-waterfall"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "RXQQRDSBWBHXGBCN"
									})
								);
							}}
						>
							Balance Sheet Bridge
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"dollar-sign"}
					size={"lg"}
					color={"#638147"}
					direction="row"
					gap="0.25rem"
					fontSize="0.85rem"
				>
					Financial Performance
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={"chart-column"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "DPTBTMXNGGSEBNGY"
									})
								);
							}}
						>
							Market Size Metrics
						</FaIcon>
						<FaIcon
							icon={"chart-column"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "AQAFYGVFYBJLMDXU"
									})
								);
							}}
						>
							Historical Sales Growth
						</FaIcon>
						<FaIcon
							icon={"chart-area"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "WUDPRNAABLVLGQSJ"
									})
								);
							}}
						>
							Market Size Forecast
						</FaIcon>
						<FaIcon
							icon={"chart-column"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "RYRECPELQTUWCEKG"
									})
								);
							}}
						>
							Industry Revenue Distribution
						</FaIcon>
						<FaIcon
							icon={"chart-area"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "HNZQGQJNUUJLKFSN"
									})
								);
							}}
						>
							Historical Spending
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"money-check-dollar-pen"}
					size={"lg"}
					color={"#4a5d78"}
					secondaryColor={"#309048"}
					secondaryOpacity={"0.6"}
					direction="row"
					gap="0.25rem"
					fontSize="0.85rem"
				>
					Market & Sales
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={"chart-pie"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "PWPKFFATYFUMDPZH"
									})
								);
							}}
						>
							Product Revenue Distribution
						</FaIcon>
						<FaIcon
							icon={"chart-column"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "LFKKEHBHCHBYJKKK"
									})
								);
							}}
						>
							Customer Revenue Distribution
						</FaIcon>
						<FaIcon
							icon={"chart-pie"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "HEZVBUPDRHYKRTPZ"
									})
								);
							}}
						>
							Product Sales Volume (Pie Chart)
						</FaIcon>
						<FaIcon
							icon={"chart-column"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "XZQJLVDMPMAGYBZE"
									})
								);
							}}
						>
							Product Sales Volume (Bar Chart)
						</FaIcon>
						<FaIcon
							icon={"chart-area"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "ASQPLZWSJMKJJPHH"
									})
								);
							}}
						>
							Supplier Concentration (Pie Chart)
						</FaIcon>
						<FaIcon
							icon={"chart-column"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "JGDNHCHFBLCQNJMU"
									})
								);
							}}
						>
							Supplier Concentration (Bar Chart)
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"layer-group"}
					size={"lg"}
					color={"#146db3"}
					direction="row"
					gap="0.25rem"
					fontSize="0.85rem"
				>
					Distribution & Composition
				</FaIcon>
			</RibonMenu>
		</RibonSection>
	);
}
