import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
	Actor,
	DealRoomProjectElementType,
	IDealRoomElementSource,
	IDealRoomElementTemplate,
	IDealRoomElementThread,
	IDealRoomMessage,
	IDealRoomProjectPageTemplate,
	IElementComment,
	IPageComment,
	IProjectComment
} from "@sage/types";
import { SidePanelView, useAuthState, useDealRoomProject, useDealRoomProjectActiveElement, useProjectSidePanel } from "@sage/state";
import { DealRoomService, LlmService } from "@sage/services";
import { Chat } from "@sage/shared/chat";
import {
	Bullet,
	Button,
	ButtonIcon,
	ButtonVariant,
	Col,
	DateHeader,
	FaIcon,
	LoadingBubble,
	Pad,
	PaneDirection,
	Popover,
	Row,
	Scroll,
	SectionTitle,
	SplitPane,
	TimeHeader
} from "@sage/shared/core";
import { PageTemplate, SourceCrawl, SourceFile, TemplateListItem } from "@sage/shared/dealrooms";
import { FormField, FormFieldVariant } from "@sage/shared/forms";
import { Uuid, isNullOrUndefined, useDebounce } from "@sage/utils";
import { usePrompts } from "@sage/prompts";
import "./ElementDetails.scss";

ElementDetails;

export function ElementDetails() {
	const { activeElement } = useDealRoomProject();
	const { sidePanelView } = useProjectSidePanel();

	if (sidePanelView === SidePanelView.BrowseElements) {
		return (
			<div className="__sage-deal-room-project-element-details-container">
				<BrowseElements />
			</div>
		);
	} else if (sidePanelView === SidePanelView.BrowseSageElements) {
		return (
			<div className="__sage-deal-room-project-element-details-container">
				<BrowseSageElements />
			</div>
		);
	} else if (sidePanelView === SidePanelView.Element && activeElement) {
		return (
			<div className="__sage-deal-room-project-element-details-container">
				<ElementSelected />
			</div>
		);
	} else if (sidePanelView === SidePanelView.ElementComments) {
		return (
			<div className="__sage-deal-room-project-element-details-container">
				<ElementComments />
			</div>
		);
	} else if (sidePanelView === SidePanelView.PageComments) {
		return (
			<div className="__sage-deal-room-project-element-details-container">
				<PageComments />
			</div>
		);
	} else if (sidePanelView === SidePanelView.ProjectComments) {
		return (
			<div className="__sage-deal-room-project-element-details-container">
				<ProjectComments />
			</div>
		);
	} else if (sidePanelView === SidePanelView.PageTemplates) {
		return (
			<div className="__sage-deal-room-project-element-details-container">
				<PageTemplates />
			</div>
		);
	} else {
		return (
			<div className="__sage-deal-room-project-element-details-container">
				<NoElements />
			</div>
		);
	}
}

function PageTemplates() {
	const { setSidePanelView, createPageFromTemplate } = useDealRoomProject();
	const [pageTemplates, setPageTemplates] = useState<IDealRoomProjectPageTemplate[]>([]);
	const [allPageTemplates, setAllPageTemplates] = useState<IDealRoomProjectPageTemplate[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>(null);

	const form = useForm();

	async function loadPageTemplates() {
		const _page_templates = await DealRoomService.GetPageTemplates();
		setPageTemplates(_page_templates);
		setAllPageTemplates(_page_templates);
	}

	function search(term: string) {
		setSearchTerm(term);
		setPageTemplates(
			allPageTemplates.filter((t) => {
				if (
					t.page_name.toLowerCase().includes(term.toLowerCase()) ||
					t.page_title?.toLowerCase().includes(term.toLowerCase()) ||
					t.page_description?.toLowerCase().includes(term.toLowerCase())
				) {
					return true;
				} else {
					return false;
				}
			})
		);
	}

	useEffect(() => {
		loadPageTemplates();
	}, []);

	return (
		<Col
			scroll
			height="100%"
			padding={"0.25rem"}
		>
			<Row
				wrap={false}
				verticalAlign="center"
			>
				<Button
					icon={ButtonIcon.MaterialArrowLeft}
					variant={ButtonVariant.IconSecondarySM}
					action={() => setSidePanelView(SidePanelView.Element)}
				/>
				<SectionTitle
					inline
					strong
					size="sm"
				>
					Saved Page Templates
				</SectionTitle>
			</Row>
			<FormField
				control={form.control}
				name="search"
				variant={FormFieldVariant.Vertical}
				placeholder="Search..."
				onChange={(e) => search(e)}
			/>
			<Col>
				{pageTemplates
					?.sort((a, b) => a.page_name.localeCompare(b.page_name))
					.map((page) => (
						<PageTemplate
							key={page.page_id}
							template={page}
							select={() => createPageFromTemplate(page)}
							searchTerm={searchTerm}
						/>
					))}
			</Col>
		</Col>
	);
}

function ElementComments() {
	const { activeElement, setSidePanelView } = useDealRoomProject();
	const [messages, setMessages] = useState<IElementComment[]>([]);

	async function loadMessages() {
		setMessages(
			(await DealRoomService.GetCommentsByElement(activeElement))
				.sort((a, b) => a.createdTimestamp - b.createdTimestamp)
				.map((comment, idx) => ({
					...comment,
					idx,
					message_id: comment.comment_id,
					message: comment.text,
					actorType: Actor.User,
					actor: comment.user_id
				}))
		);
	}

	async function createMessage(text: string) {
		await DealRoomService.SaveElementComment({ element_id: activeElement, text });
		await loadMessages();
	}

	useEffect(() => {
		loadMessages();
	}, [activeElement]);

	return (
		<Col height={"100%"}>
			<Pad>
				<Row
					wrap={false}
					verticalAlign="center"
				>
					<Button
						icon={ButtonIcon.MaterialArrowLeft}
						variant={ButtonVariant.IconSecondarySM}
						action={() => setSidePanelView(SidePanelView.Element)}
					/>
					<SectionTitle
						inline
						strong
						size="sm"
					>
						Element Comments
					</SectionTitle>
				</Row>
			</Pad>
			<Chat
				messages={messages}
				send={createMessage}
				hideStyle
				hideQuickActions
				comment
			/>
		</Col>
	);
}

function PageComments() {
	const { activePage, setSidePanelView } = useDealRoomProject();
	const [messages, setMessages] = useState<IPageComment[]>([]);

	async function loadMessages() {
		setMessages(
			(await DealRoomService.GetCommentsByPage(activePage))
				.sort((a, b) => a.createdTimestamp - b.createdTimestamp)
				.map((comment, idx) => ({
					...comment,
					idx,
					message_id: comment.comment_id,
					message: comment.text,
					actorType: Actor.User,
					actor: comment.user_id
				}))
		);
	}

	async function createMessage(text: string) {
		await DealRoomService.SavePageComment({ page_id: activePage, text });
		await loadMessages();
	}

	useEffect(() => {
		loadMessages();
	}, [activePage]);

	return (
		<Col height={"100%"}>
			<Pad>
				<Row
					wrap={false}
					verticalAlign="center"
				>
					<Button
						icon={ButtonIcon.MaterialArrowLeft}
						variant={ButtonVariant.IconSecondarySM}
						action={() => setSidePanelView(SidePanelView.Element)}
					/>
					<SectionTitle
						inline
						strong
						size="sm"
					>
						Page Comments
					</SectionTitle>
				</Row>
			</Pad>
			<Chat
				messages={messages}
				send={createMessage}
				hideStyle
				hideQuickActions
				comment
			/>
		</Col>
	);
}

function ProjectComments() {
	const { project_id, setSidePanelView } = useDealRoomProject();
	const [messages, setMessages] = useState<IProjectComment[]>([]);

	async function loadMessages() {
		setMessages(
			(await DealRoomService.GetCommentsByProject(project_id))
				.sort((a, b) => a.createdTimestamp - b.createdTimestamp)
				.map((comment, idx) => ({
					...comment,
					idx,
					message_id: comment.comment_id,
					message: comment.text,
					actorType: Actor.User,
					actor: comment.user_id
				}))
		);
	}

	async function createMessage(text: string) {
		await DealRoomService.SaveProjectComment({ project_id, text });
		await loadMessages();
	}

	useEffect(() => {
		loadMessages();
	}, [project_id]);

	return (
		<Col height={"100%"}>
			<Pad>
				<Row
					wrap={false}
					verticalAlign="center"
				>
					<Button
						icon={ButtonIcon.MaterialArrowLeft}
						variant={ButtonVariant.IconSecondarySM}
						action={() => setSidePanelView(SidePanelView.Element)}
					/>
					<SectionTitle
						inline
						strong
						size="sm"
					>
						Project Comments
					</SectionTitle>
				</Row>
			</Pad>
			<Chat
				messages={messages}
				send={createMessage}
				hideStyle
				hideQuickActions
				comment
			/>
		</Col>
	);
}

function BrowseElements() {
	const { addElement, setSidePanelView } = useDealRoomProject();
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [templates, setTemplates] = useState<IDealRoomElementTemplate[]>([]);
	const [allTemplates, setAllTemplates] = useState<IDealRoomElementTemplate[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const form = useForm();

	async function search(term: string) {
		setLoading(true);
		setSearchTerm(term);
		const response = await DealRoomService.SearchTeamTemplates(term);
		if (response) {
			setTemplates(response);
		} else {
			setTemplates(allTemplates);
		}
		setLoading(false);
	}

	const searchDebounce = useDebounce(search, 1000);

	async function loadTemplates() {
		let _templates = await DealRoomService.GetTemplateByTeam();
		const _recent_templates = await DealRoomService.GetRecentTeamElements();
		const _recent_ids = _recent_templates.map((el) => el.element_id);
		_templates = _templates.filter((el) => !_recent_ids.includes(el.element_id));
		setTemplates([..._recent_templates, ..._templates]);
		setAllTemplates([..._recent_templates, ..._templates]);
	}

	async function selectTeamElement(template) {
		addElement(template.element_type, template.element_id);
		await DealRoomService.EmitTeamTemplateUse(template.element_id);
	}

	useEffect(() => {
		loadTemplates();
	}, []);

	return (
		<Col
			scroll
			height="100%"
			padding={"0.25rem"}
		>
			<Row
				wrap={false}
				verticalAlign="center"
			>
				<Button
					icon={ButtonIcon.MaterialArrowLeft}
					variant={ButtonVariant.IconSecondarySM}
					action={() => setSidePanelView(SidePanelView.Element)}
				/>
				<SectionTitle
					inline
					strong
					size="sm"
				>
					Your Teams Elements
				</SectionTitle>
			</Row>
			<FormField
				control={form.control}
				name="search"
				variant={FormFieldVariant.Vertical}
				placeholder="Search..."
				onChange={(e) => {
					setLoading(true);
					searchDebounce(e);
				}}
			/>
			{loading ? (
				<Row horizontalAlign="center">
					<LoadingBubble />
				</Row>
			) : (
				templates.map((template) => (
					<TemplateListItem
						key={template.element_id}
						template={template}
						select={() => selectTeamElement(template)}
						searchTerm={searchTerm}
					/>
				))
			)}{" "}
		</Col>
	);
}

function BrowseSageElements() {
	const { addElement, setSidePanelView } = useDealRoomProject();
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [templates, setTemplates] = useState<IDealRoomElementTemplate[]>([]);
	const [allTemplates, setAllTemplates] = useState<IDealRoomElementTemplate[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const form = useForm();

	async function search(term: string) {
		setLoading(true);
		setSearchTerm(term);
		const response = await DealRoomService.SearchSageTemplates(term);
		if (response) {
			setTemplates(response);
		} else {
			setTemplates(allTemplates);
		}
		setLoading(false);
	}

	const searchDebounce = useDebounce(search, 1000);

	async function loadTemplates() {
		let _templates = await DealRoomService.GetSageTemplates();
		const _recent_templates = await DealRoomService.GetRecentSageElements();
		const _recent_ids = _recent_templates.map((el) => el.element_id);
		_templates = _templates.filter((el) => !_recent_ids.includes(el.element_id));
		setTemplates([..._recent_templates, ..._templates]);
		setAllTemplates([..._recent_templates, ..._templates]);
	}

	async function selectSageElement(template) {
		addElement(template.element_type, template.element_id);
		await DealRoomService.EmitSageTemplateUse(template.element_id);
	}

	useEffect(() => {
		loadTemplates();
	}, []);

	return (
		<Col
			scroll
			height="100%"
			padding={"0.25rem"}
		>
			<Row
				wrap={false}
				verticalAlign="center"
			>
				<Button
					icon={ButtonIcon.MaterialArrowLeft}
					variant={ButtonVariant.IconSecondarySM}
					action={() => setSidePanelView(SidePanelView.Element)}
				/>
				<SectionTitle
					inline
					strong
					size="sm"
				>
					Sage Elements
				</SectionTitle>
			</Row>
			<FormField
				control={form.control}
				name="search"
				variant={FormFieldVariant.Vertical}
				placeholder="Search..."
				onChange={(e) => {
					searchDebounce(e);
					setLoading(true);
				}}
			/>
			{loading ? (
				<Row horizontalAlign="center">
					<LoadingBubble />
				</Row>
			) : (
				templates.map((template) => (
					<TemplateListItem
						key={template.element_id}
						template={template}
						select={() => selectSageElement(template)}
						searchTerm={searchTerm}
					/>
				))
			)}
		</Col>
	);
}

function ElementSelected() {
	const authState = useAuthState();
	const [thread, setThread] = useState<IDealRoomElementThread>(null);
	const [messages, setMessages] = useState<IDealRoomMessage[]>([]);
	const [sources, setSources] = useState<IDealRoomElementSource[]>([]);
	const [llmResponse, setLlmResponse] = useState<string>(null);
	const [inProgressMessageKey, setInProgressMessageKey] = useState<string>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const prompts = usePrompts();
	const { setSidePanelView, deal_id, loadElements, refreshElement, crawls } = useDealRoomProject();
	const { element } = useDealRoomProjectActiveElement();

	async function loadThread() {
		if (!isNullOrUndefined(element?.element_id)) {
			const threads = await DealRoomService.GetThreadsByElement(element.element_id);
			if (threads.length === 0) {
				const _thread = await DealRoomService.SaveElementThread({ element_id: element.element_id });
				setThread(_thread);
			} else {
				setThread(threads[0]);
			}
		}
	}

	async function loadMessages() {
		if (!isNullOrUndefined(thread?.thread_id)) {
			setMessages((await DealRoomService.GetElementMessagesByThread(thread.thread_id)).map((message) => ({ ...message })));
		}
	}

	async function loadSources() {
		if (!isNullOrUndefined(element?.element_id)) {
			setSources([
				...(await DealRoomService.GetSourcesByElement(element.element_id)),
				...(await DealRoomService.GetCrawlSources(element.element_id))
			]);
		}
	}

	const [elementIcon, elementIconColor] = useMemo(() => {
		if (element?.element_type === DealRoomProjectElementType.Chart) {
			return ["chart-column", "#0051a8"];
		} else if (element?.element_type === DealRoomProjectElementType.Table) {
			return ["file-spreadsheet", "#00a857"];
		} else if (element?.element_type === DealRoomProjectElementType.Bullets) {
			return ["list-ul", "#30abc6"];
		} else if (element?.element_type === DealRoomProjectElementType.Paragraph) {
			return ["align-left", "#a80300"];
		} else if (element?.element_type === DealRoomProjectElementType.Custom) {
			return ["message-bot", "#5700a8"];
		} else {
			return [null, null];
		}
	}, [element]);

	const triesRef = useRef(0);

	function generateText(request, cb): Promise<string> {
		if (triesRef.current < 40) {
			triesRef.current = triesRef.current + 1;
			return new Promise(async (resolve) => {
				try {
					const { generated_text } = await LlmService.Stream(request, cb);

					if (generated_text.length === 0) {
						setTimeout(async () => {
							resolve(await generateText(request, cb));
						}, 2000);
					} else {
						resolve(generated_text);
					}
				} catch (e) {
					setTimeout(async () => {
						resolve(await generateText(request, cb));
					}, 2000);
				}
			});
		} else {
			return new Promise((resolve) => resolve('<flag number="1">An Error ocoured, please try again</flag>'));
		}
	}

	async function sendQuickAction(text: string) {
		setLoading(true);
		const firstIdx = messages.length;
		const secondIdx = firstIdx + 1;

		const message = await DealRoomService.SaveElementMessage({
			thread_id: thread.thread_id,
			text,
			actorType: Actor.User,
			actor: authState.user.user_id,
			idx: firstIdx
		});

		setMessages((m) => [...m, message]);

		const newMessageId = Uuid.Nano();

		triesRef.current = 0;
		const generated_text = await generateText(
			{
				prompt: text,
				preprompt: prompts.dealRoom(element.element_type),
				thread: thread.thread_id
			},
			(e) => {
				setMessages((m) => {
					return [
						...m.filter((_m) => _m.message_id !== newMessageId),
						{
							createdTimestamp: new Date().getTime(),
							lastModifiedTimestamp: new Date().getTime(),
							idx: secondIdx,
							actor: "Athena",
							actorType: Actor.Assistant,
							message_id: newMessageId,
							text: e,
							thread_id: thread.thread_id
						}
					];
				});
			}
		);
		triesRef.current = 0;

		await DealRoomService.SaveElementMessage({
			thread_id: thread.thread_id,
			text: generated_text,
			actorType: Actor.Assistant,
			actor: "Athena",
			idx: secondIdx,
			message_id: newMessageId
		});
		setLoading(false);
	}

	async function sendChartQuickAction(text: string) {
		setLoading(true);
		const firstIdx = messages.length;
		const secondIdx = firstIdx + 1;
		const newMessageId = Uuid.Nano();

		const message = await DealRoomService.SaveElementMessage({
			thread_id: thread.thread_id,
			text,
			actorType: Actor.User,
			actor: authState.user.user_id,
			idx: firstIdx
		});

		setMessages((m) => [...m, message]);

		triesRef.current = 0;
		const generated_text = await generateText(
			{
				prompt: text,
				preprompt: prompts.dealRoom(DealRoomProjectElementType.Chart),
				thread: thread.thread_id
			},
			(e) => {
				setMessages((m) => {
					return [
						...m.filter((_m) => _m.message_id !== newMessageId),
						{
							createdTimestamp: new Date().getTime(),
							lastModifiedTimestamp: new Date().getTime(),
							idx: secondIdx,
							actor: "Athena",
							actorType: Actor.Assistant,
							message_id: newMessageId,
							text: e,
							thread_id: thread.thread_id
						}
					];
				});
			}
		);
		triesRef.current = 0;

		await DealRoomService.SaveElementMessage({
			thread_id: thread.thread_id,
			text: generated_text,
			actorType: Actor.Assistant,
			actor: "Athena",
			idx: secondIdx,
			message_id: newMessageId
		});
		setLoading(false);
	}

	async function send(text: string) {
		setLoading(true);
		const newMessageId = Uuid.Nano();
		const firstIdx = messages.length;
		const secondIdx = firstIdx + 1;

		const message = await DealRoomService.SaveElementMessage({
			thread_id: thread.thread_id,
			text,
			actorType: Actor.User,
			actor: authState.user.user_id,
			idx: firstIdx
		});

		setMessages((m) => [...m, message]);

		let searchResults = (
			await Promise.all([
				DealRoomService.SearchSources(deal_id, text, element.element_id),
				...crawls.map((crawl) => DealRoomService.SearchWebData(crawl.host, text))
			])
		).flat();
		const sourceChunks = sources.map((s) => s.chunk_id);
		const sourcePages = sources.map((s) => s.page_id);
		searchResults = [
			...searchResults.filter((result) =>
				"chunk_id" in result ? !sourceChunks.includes(result.chunk_id) : !sourcePages.includes(result.page_id)
			)
		]
			.sort((a, b) => b.relevance_score - a.relevance_score)
			.slice(0, 20);

		setSources([...sources, ...searchResults]);

		triesRef.current = 0;
		const generated_text = await generateText(
			{
				prompt: text,
				context: JSON.stringify(searchResults),
				preprompt: prompts.dealRoom(element.element_type),
				thread: thread.thread_id
			},
			(e) => {
				setMessages((m) => {
					return [
						...m.filter((_m) => _m.message_id !== newMessageId),
						{
							createdTimestamp: new Date().getTime(),
							lastModifiedTimestamp: new Date().getTime(),
							idx: secondIdx,
							actor: "Athena",
							actorType: Actor.Assistant,
							message_id: newMessageId,
							text: e,
							thread_id: thread.thread_id
						}
					];
				});
			}
		);
		triesRef.current = 0;

		await DealRoomService.SaveElementMessage({
			thread_id: thread.thread_id,
			text: generated_text,
			actorType: Actor.Assistant,
			actor: "Athena",
			idx: secondIdx
		});
		setLoading(false);
		await Promise.all(
			searchResults.map((result) => {
				if ("chunk_id" in result) {
					return DealRoomService.CreateElementSource(result);
				} else {
					return DealRoomService.CreateCrawlSource({ ...result, element_id: element.element_id });
				}
			})
		);
	}

	async function updateElementText(e, message) {
		e.preventDefault();
		e.stopPropagation();
		await DealRoomService.UpdateElementText(element.element_id, message.text);
		loadElements();
	}

	useEffect(() => {
		loadMessages();
	}, [thread?.thread_id, refreshElement]);

	useEffect(() => {
		loadThread();
		loadSources();
	}, [element, refreshElement]);

	return (
		<div className="element-details-inner">
			<div className="split-section">
				<SplitPane
					direction={PaneDirection.Vertical}
					defaultSplit={0.25}
				>
					<Col
						height={"100%"}
						gap="0rem"
					>
						<Row
							verticalAlign="center"
							horizontalAlign="between"
							padding="0.25rem"
						>
							<Row
								verticalAlign="center"
								wrap={false}
								gap="0.5rem"
							>
								<FaIcon
									icon={elementIcon}
									color={elementIconColor}
									width="fit-content"
									size={"xl"}
									borderRadius={"0.5rem"}
									paddingInline="0.75rem"
									padding="0.5rem"
									border
									hideBg
									direction="row"
								/>
								<Col gap={"0.25rem"}>
									<SectionTitle
										strong
										inline
										caps
										size="sm"
									>
										{element?.element_name}
									</SectionTitle>
								</Col>
							</Row>
							<FaIcon
								icon={"comments"}
								color={"#4272a0"}
								width="fit-content"
								size={"xl"}
								borderRadius={"0.5rem"}
								paddingInline="0.75rem"
								padding="0.5rem"
								onClick={() => setSidePanelView(SidePanelView.ElementComments)}
							></FaIcon>
						</Row>
						<div className="chat-window">
							<Chat
								messages={messages}
								send={send}
								sendQuickAction={sendQuickAction}
								sendChartQuickAction={sendChartQuickAction}
								hideStyle
								inProgressMessage={llmResponse}
								messageActions={(message) =>
									message.actorType === Actor.Assistant ? (
										<FaIcon
											icon={"circle-left"}
											color={"#5da035"}
											size="xl"
											onClick={(e) => updateElementText(e, message)}
										>
											Update
										</FaIcon>
									) : null
								}
								hideQuickActions={
									element?.element_type !== DealRoomProjectElementType.Paragraph &&
									element?.element_type !== DealRoomProjectElementType.Bullets
								}
								loading={loading}
							/>
						</div>
					</Col>
					<Scroll>
						<Pad>
							<Col
								gap="0.5rem"
								height={"100%"}
							>
								<SectionTitle
									strong
									size="sm"
								>
									<Row
										verticalAlign="center"
										wrap={false}
									>
										Source Files
										<Popover>
											These are the source files that were used to generate your elements.
											<br />
											<br />
											You can click on them to open them.
										</Popover>
									</Row>
								</SectionTitle>
								<Col gap={"1rem"}>
									{sources
										.sort((b, a) => a.relevance_score - b.relevance_score)
										.map((s) =>
											"chunk_id" in s ? (
												<SourceFile
													key={s.chunk_id || s.element_source_id || s.source_id}
													source={s}
												/>
											) : (
												<SourceCrawl
													key={s.source_id}
													crawl={s}
												/>
											)
										)}
								</Col>
							</Col>
						</Pad>
					</Scroll>
				</SplitPane>
			</div>
		</div>
	);
}

function NoElements() {
	return (
		<Pad>
			<Row
				verticalAlign="top"
				wrap={false}
			>
				<FaIcon
					icon={"image"}
					color={"#1e3050"}
					width="fit-content"
					size={"2x"}
					borderRadius={"0.5rem"}
					paddingInline="0.75rem"
					padding="0.5rem"
					border
					hideBg
					direction="row"
				/>
				<Col gap={"0"}>
					<SectionTitle
						strong
						inline
					>
						No Element Selected
					</SectionTitle>
				</Col>
			</Row>
		</Pad>
	);
}
