import { IChronosEvent } from "@sage/types";
import { HttpService } from "../HttpService";

export function GetUserEvents(user_id: string): Promise<IChronosEvent[]> {
	return HttpService.Get<IChronosEvent[]>({ path: `/_admin/chronos/user/${user_id}` }).then((res) => res.payload);
}

export function ListUserEvents(user_id: string): Promise<IChronosEvent[]> {
	return HttpService.Get<IChronosEvent[]>({ path: `/_admin/chronos/user/list/${user_id}` }).then((res) => res.payload);
}

export function GetTeamEvents(team_code: string): Promise<IChronosEvent[]> {
	return HttpService.Get<IChronosEvent[]>({ path: `/_admin/chronos/team/${team_code}` }).then((res) => res.payload);
}

export function ListTeamEvents(team_code: string): Promise<IChronosEvent[]> {
	return HttpService.Get<IChronosEvent[]>({ path: `/_admin/chronos/team/list/${team_code}` }).then((res) => res.payload);
}

export function GetRequestEvents(request_id: string): Promise<IChronosEvent[]> {
	return HttpService.Get<IChronosEvent[]>({ path: `/_admin/chronos/request/${request_id}` }).then((res) => res.payload);
}

export function Sql(query: string) {
	return HttpService.Post({ path: "/_admin/chronos/sql", body: { query } }).then((res) => res.payload);
}
