import { ITeam, IUser } from "@sage/types";
import { HttpService } from "../HttpService/HttpService";

export class UserService {
	static CreateLeadMagnetUser(user_id: string) {
		return HttpService.Post<{ user_id: string }>({
			uri: "/workspace-api-v2/users/register-lead-magnet",
			body: { email: user_id, password: "Password!1" }
		}).then((response) => response.payload);
	}

	static CreateUser(body: { email: string; password: string }) {
		return HttpService.Post<{ user_id: string }>({
			uri: "/workspace-api-v2/users/register",
			body
		}).then((response) => response.payload);
	}

	static VerifyEmail(body: { user_id: string; verificationCode: string }) {
		return HttpService.Post({
			uri: "/workspace-api-v2/users/verify",
			body
		}).then((response) => response.payload);
	}

	static async CreateTeam(body: { teamName: string; teamCode: string; emailDomain: string }) {
		const response = await HttpService.Post<{ team_id: string }>({
			uri: "/workspace-api-v2/teams",
			body
		});
		if (response.statusCode === 200) {
			return true;
		} else {
			return false;
		}
	}

	static async JoinTeam(body: { teamCode: string; user_id: string }) {
		const response = await HttpService.Post({
			uri: "/workspace-api-v2/users/join-team",
			body
		});

		if (response.statusCode === 200) {
			return true;
		} else {
			return false;
		}
	}

	static UpdateUser(body: IUser) {
		return HttpService.Post({ uri: "/workspace-api-v2/users", body }).then((response) => response.payload);
	}

	static async RequestUserPasswordReset(body: { email: string }) {
		return HttpService.Post<{ user_id: string }>({
			uri: "/workspace-api-v2/users/reset/request",
			body
		}).then((response) => response.payload);
	}

	static async VerifyResetPassword(body: { user_id: string; verificationCode: string }) {
		return HttpService.Post<{ response: boolean }>({
			uri: "/workspace-api-v2/users/reset/verify",
			body
		}).then((response) => response.statusCode);
	}

	static async resetPassword(body: { user_id: string; email: string; password: string; verificationCode: string }) {
		return HttpService.Post({
			uri: "/workspace-api-v2/users/reset",
			body
		});
	}

	static async GetUsersByTeamCode() {
		return HttpService.Get<IUser[]>({
			uri: `/workspace-api-v2/users/team`
		}).then((response) => response.payload);
	}

	static async GetUserById() {
		return HttpService.Get<{ user: IUser; team: ITeam }>({
			uri: `/workspace-api-v2/users/full`
		}).then((response) => response.payload);
	}

	static async GetUserByIdShallow() {
		return HttpService.Get<IUser>({
			uri: `/workspace-api-v2/users`
		}).then((response) => response.payload);
	}
}
