import { Uuid } from "@sage/utils";
import { Credentials } from "../Credentials/Credentials";
import { HttpService } from "../HttpService/HttpService";

export interface ILoginResponse {
	AccessToken: string;
	ExpiresIn: number;
	IdToken: string;
	RefreshToken: string;
	TokenType: string;
}

export class AuthService {
	static async Authenticate() {
		let old_token = Credentials.RetrieveToken();

		if (old_token && old_token["RefreshToken"]) {
			let translatedToken;
			try {
				if (new Date().getTime() < old_token["ExpiresAt"]) {
					translatedToken = old_token;
				} else {
					const token = await fetch(`/workspace-api-v2/users/token`, {
						method: "POST",
						body: JSON.stringify({ refresh_token: old_token["RefreshToken"] }),
						headers: {
							"Content-Type": "application/json",
							"x-sage-request-id": Uuid.Nano(24)
						}
					});
					const token_json = await token.json();

					translatedToken = {
						AccessToken: token_json["access_token"],
						RefreshToken: token_json["refresh_token"] || old_token["RefreshToken"],
						IdToken: token_json["id_token"],
						ExpiresAt: new Date().getTime() + token_json["expires_in"] * 900
					};

					await HttpService.UpdateCredentialsEncryptionKey({
						...(old_token as any),
						...translatedToken
					});
					Credentials.StoreToken({
						...(old_token as any),
						...translatedToken
					});
				}

				const id_token = translatedToken.IdToken;
				const jwt = AuthService.ParseJWT(id_token);
				const email = jwt["cognito:username"];
				const user_id = jwt["sub"];

				return { email, user_id };
			} catch (e) {
				Credentials.PurgeToken();
				Credentials.PurgeEncryptionKey();
				return null;
			}
		}

		return null;
	}

	static async Login(body: { email: string; password: string }) {
		const res = await HttpService.Post<ILoginResponse>({
			uri: "/workspace-api-v2/users/login",
			body
		});

		const verificationRes = await HttpService.Post<{ is_verified: boolean; user_id: string; has_team: string }>({
			uri: "/workspace-api-v2/users/verified",
			body: { email: body.email }
		});

		const isVerified = verificationRes.payload?.is_verified;
		const hasTeam = verificationRes.payload?.has_team;
		const loginSuccessful = res.statusCode === 200;

		if (!loginSuccessful && !isVerified) {
			return { is_verified: isVerified, user_id: verificationRes.payload.user_id, hasTeam: hasTeam };
		}

		const idToken = res.payload.IdToken;

		const userDetails = AuthService.ParseJWT(idToken);

		await HttpService.UpdateCredentialsEncryptionKey(res.payload);
		Credentials.StoreToken(res.payload);

		return {
			user_id: userDetails["sub"],
			email: userDetails["cognito:username"],
			is_verified: isVerified,
			has_team: hasTeam
		};
	}

	static ParseJWT(token: string) {
		var base64Url = token.split(".")[1];
		var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
		var jsonPayload = decodeURIComponent(
			window
				.atob(base64)
				.split("")
				.map(function (c) {
					return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join("")
		);

		return JSON.parse(jsonPayload);
	}
}
