import { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { useScreenshot } from "use-react-screenshot";
import { Button, ButtonIcon, ButtonVariant, Col, Collapsible, P, Row } from "@sage/shared/core";
import { isNullOrUndefined } from "@sage/utils";
import "./ChartProvider.scss";

interface IDims {
	height: number;
	width: number;
	marginTop: number;
	marginBottom: number;
	marginLeft: number;
	marginRight: number;
}

const DimsContext = createContext<IDims>(null);

export function Chart({
	children,
	legend,
	title,
	updateColor,
	data
}: {
	children: ReactNode;
	legend?: { category: string; color: string }[];
	title?: string;
	updateColor?: (category: string, color: string) => void;
	data?: any[];
}) {
	const containerRef = useRef(null);
	const outerRef = useRef(null);
	const [dims, setDims] = useState<IDims>(null);
	const [image, screenshot] = useScreenshot();
	const [copiedData, setCopiedData] = useState(false);
	const [screenshotted, setScreenshotted] = useState(false);

	function updateDims() {
		const bbox = containerRef.current?.getBoundingClientRect();
		if (bbox) {
			setDims({
				height: bbox.width / 2,
				width: bbox.width,
				marginTop: 30,
				marginLeft: 50,
				marginBottom: 50,
				marginRight: 10
			});
		}
	}

	useEffect(() => {
		updateDims();
	}, [containerRef.current]);

	function takeScreenshot(e) {
		e.preventDefault();
		e.stopPropagation();
		screenshot(outerRef.current);
		setScreenshotted(true);
		setTimeout(() => {
			setScreenshotted(false);
		}, 1000);
	}

	function fileToBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = () => {
				resolve(reader.result);
			};

			reader.onerror = (error) => reject(error);
			reader.readAsDataURL(file);
		});
	}

	async function setImageToClipboard() {
		if (image) {
			const blob = new Blob([`<img src="${image}">`], { type: "text/html" });
			const clipboardItem = new ClipboardItem({
				[blob.type]: blob
			});
			await navigator.clipboard.write([clipboardItem]);
		}
	}

	async function getSourceData(e) {
		e.preventDefault();
		e.stopPropagation();
		console.log({ data });

		const tableData = {};

		for (let row of data) {
			if (row.date in tableData) {
				tableData[row.date][row.category] = row.value;
			} else {
				tableData[row.date] = { [row.category]: row.value, date: row.date };
			}
		}

		const trows = [];

		for (let row of Object.values(tableData)) {
			let d = row.date;
			delete row.date;
			trows.push(
				`<tr><td>${d}</td>${Object.values(row)
					.map((v) => `<td>${v}</td>`)
					.join("")}</tr>`
			);
		}

		let html = `<table><thead><tr><th>Date</th>${Object.keys(Object.values(tableData)[0])
			.filter((a) => a !== "date")
			.map((k) => `<th>${k}</th>`)
			.join("")}</tr></thead><tbody>${trows.join("")}</tbody></table>`;

		try {
			await navigator.clipboard.write([
				new ClipboardItem({
					"text/html": new Blob([html], { type: "text/html" })
				})
			]);

			setCopiedData(true);
			setTimeout(() => {
				setCopiedData(false);
			}, 1000);
		} catch (err) {
			console.error("Failed to copy:", err);
		}
	}

	useEffect(() => {
		setImageToClipboard();
	}, [image]);

	function handleResize() {
		updateDims();
	}

	useEffect(() => {
		if (containerRef.current) {
			const observer = new ResizeObserver(handleResize);
			observer.observe(containerRef.current);
			return () => {
				try {
					observer.unobserve(containerRef.current);
				} catch (e) {}
			};
		}
	}, [containerRef.current]);

	return (
		<DimsContext.Provider value={dims}>
			<div
				ref={outerRef}
				className="__sage-chart-provider-container"
			>
				{title && <P>{title}</P>}
				<Row>
					<div style={{ flex: 1 }}>
						<div
							ref={containerRef}
							style={{ width: "100%" }}
						>
							{dims && children}
						</div>
					</div>
					{legend && (
						<Legend
							legend={legend}
							updateColor={updateColor ? updateColor : undefined}
						/>
					)}
				</Row>
			</div>
			<div className="__sage-chart-save-btn">
				<Row>
					<div
						className={`copy-text ${screenshotted ? "copied" : ""}`}
						onClick={takeScreenshot}
					>
						<img
							src={
								screenshotted
									? "https://cdn.ccm.vc/sage/icons/material-check-white.svg"
									: "https://cdn.ccm.vc/sage/icons/material-save.svg"
							}
						/>
					</div>
					<div
						className={`copy-text ${copiedData ? "copied" : ""}`}
						onClick={getSourceData}
					>
						<img
							src={
								copiedData
									? "https://cdn.ccm.vc/sage/icons/material-check-white.svg"
									: "https://cdn.ccm.vc/sage/icons/material-export.svg"
							}
						/>
					</div>
				</Row>
			</div>
		</DimsContext.Provider>
	);
}

function Legend({ legend, updateColor }) {
	return (
		<div className="__sage-charts-legend">
			{legend.map(({ color, category, outline }) => (
				<LegendItem
					key={category}
					category={category}
					color={color}
					outline={outline}
					updateColor={updateColor ? updateColor : undefined}
				/>
			))}
		</div>
	);
}

function LegendItem({ color, category, outline, updateColor }) {
	const [open, setOpen] = useState<boolean>(false);
	const [colorInner, setColor] = useState<string>(color);

	function updateColorManually(e: string) {
		setColor(e);
		console.log(e.includes("#") && (e.length === 7 || e.length === 9));
		if (e.includes("#") && (e.length === 7 || e.length === 9)) {
			updateColor(category, e);
		}
	}

	const colorFamilies = [
		{
			name: "green",
			colors: ["#004d00", "#007932", "#66b3a1", "#b2e0d5"]
		},
		{
			name: "blue",
			colors: ["#003466", "#01509d", "#007acd", "#66a3fe"]
		},
		{
			name: "lightblue",
			colors: ["#0e47a1", "#1976d3", "#42a5f6", "#90caf8"]
		},
		{
			name: "indigo",
			colors: ["#1a237e", "#3949ab", "#5c6bc0", "#9ea8db"]
		},
		{
			name: "purple",
			colors: ["#4b0081", "#690dac", "#a64dff", "#d1a6ff"]
		},
		{
			name: "red",
			colors: ["#990100", "#cc3433", "#ff6766", "#ff9899"]
		},
		{
			name: "orange",
			colors: ["#fe5722", "#ff8b66", "#ffab91", "#ffd2c4"]
		}
	];

	return (
		<div className="__sage-charts-legend-item">
			<Row
				key={category}
				verticalAlign="center"
				gap={"0.5rem"}
			>
				<div
					className={`swatch ${outline ? "outline" : ""}`}
					style={{ backgroundColor: outline ? `${color}80` : color, border: outline ? `solid 2px ${color}B3` : "" }}
					onClick={(e) => {
						if (!isNullOrUndefined(updateColor)) {
							e.preventDefault();
							e.stopPropagation();
							setOpen((e) => !e);
						}
					}}
				/>
				<div className="category">{category.replace("&amp;", "&")}</div>
				<div className="swatch-switcher">
					<Collapsible
						visible={open}
						allowOverflow
					>
						<Col>
							<Row wrap={false}>
								{colorFamilies.map((p) => (
									<Col key={p.name}>
										{p.colors.map((c) => (
											<div
												key={c}
												className={`swatch ${outline ? "outline" : ""}`}
												style={{ backgroundColor: outline ? `${c}80` : c, border: c ? `solid 2px ${c}B3` : "" }}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													if (!isNullOrUndefined(updateColor)) {
														updateColor(category, c);
													}
													setOpen(false);
												}}
											/>
										))}
									</Col>
								))}
							</Row>
							<input
								value={colorInner}
								className="color-picker-input"
								onChange={(e) => updateColorManually(e.target.value)}
							/>
						</Col>
					</Collapsible>
				</div>
			</Row>
		</div>
	);
}

export function useDims() {
	const dims = useContext(DimsContext);
	return { ...dims };
}

export function format_value(value) {
	let intermediate_value = value;

	if (intermediate_value >= 1000) {
		(intermediate_value = intermediate_value / 1000).toFixed(2);
	} else {
		return (Math.round(intermediate_value * 100) / 100).toFixed(2);
	}

	if (intermediate_value >= 1000) {
		intermediate_value = intermediate_value / 1000;
	} else {
		return `${(Math.round(intermediate_value * 100) / 100).toFixed(2)} K`;
	}

	if (intermediate_value >= 1000) {
		intermediate_value = intermediate_value / 1000;
	} else {
		return `${(Math.round(intermediate_value * 100) / 100).toFixed(2)} M`;
	}

	if (intermediate_value >= 1000) {
		intermediate_value = intermediate_value / 1000;
	} else {
		return `${(Math.round(intermediate_value * 100) / 100).toFixed(2)} B`;
	}

	if (intermediate_value >= 1000) {
		intermediate_value = intermediate_value / 1000;
	} else {
		return `${(Math.round(intermediate_value * 100) / 100).toFixed(2)} T`;
	}

	return value;
}

export function format_tick(value) {
	let intermediate_value = value;

	if (intermediate_value >= 1000) {
		intermediate_value = intermediate_value / 1000;
	} else {
		return Math.round(intermediate_value * 100) / 100;
	}

	if (intermediate_value >= 1000) {
		intermediate_value = intermediate_value / 1000;
	} else {
		return `${Math.round(intermediate_value * 100) / 100} K`;
	}

	if (intermediate_value >= 1000) {
		intermediate_value = intermediate_value / 1000;
	} else {
		return `${Math.round(intermediate_value * 100) / 100} M`;
	}

	if (intermediate_value >= 1000) {
		intermediate_value = intermediate_value / 1000;
	} else {
		return `${Math.round(intermediate_value * 100) / 100} B`;
	}

	if (intermediate_value >= 1000) {
		intermediate_value = intermediate_value / 1000;
	} else {
		return `${Math.round(intermediate_value * 100) / 100} T`;
	}

	return value;
}
