import { useDealRoom } from "@sage/state";
import { DeleteConfirmation, Pad } from "@sage/shared/core";
import { InfoRequestCard, Project, TemplateListItem, Thread } from "@sage/shared/dealrooms";
import { isNullOrUndefined } from "@sage/utils";

export function DeleteModals() {
	const {
		deleteProject,
		setDeleteProject,
		confirmDeleteProject,
		deleteThread,
		setDeleteThread,
		confirmDeleteThread,
		deleteInfoRequest,
		confirmDeleteInfoRequest,
		setDeleteInfoRequest,
		deleteElement,
		setDeleteElement,
		confirmDeleteElement
	} = useDealRoom();

	return (
		<>
			<DeleteConfirmation
				visible={!isNullOrUndefined(deleteProject)}
				onDismiss={() => setDeleteProject(null)}
				onConfirm={confirmDeleteProject}
			>
				<Pad>
					<Project project={deleteProject} />
				</Pad>
			</DeleteConfirmation>
			<DeleteConfirmation
				visible={!isNullOrUndefined(deleteThread)}
				onDismiss={() => setDeleteThread(null)}
				onConfirm={confirmDeleteThread}
			>
				<Pad>
					<Thread thread={deleteThread} />
				</Pad>
			</DeleteConfirmation>
			<DeleteConfirmation
				visible={!isNullOrUndefined(deleteInfoRequest)}
				onDismiss={() => setDeleteInfoRequest(null)}
				onConfirm={confirmDeleteInfoRequest}
			>
				<Pad>
					<InfoRequestCard request={deleteInfoRequest} />
				</Pad>
			</DeleteConfirmation>
			<DeleteConfirmation
				visible={!isNullOrUndefined(deleteElement)}
				onDismiss={() => setDeleteElement(null)}
				onConfirm={confirmDeleteElement}
			>
				<Pad>
					<TemplateListItem template={deleteElement} />
				</Pad>
			</DeleteConfirmation>
		</>
	);
}
