import React, { useReducer } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthContext, AuthReducer, InitialAuthState } from "@sage/state";
import {
	AdminGuard,
	AnalyticsGuard,
	AthenaGuard,
	AuthGuard,
	DealsGuard,
	DecksGuard,
	DocsGuard,
	FsGuard,
	NewsGuard,
	OnboardingLayoutGuard,
	VaultGuard,
	WorkspaceGuard
} from "@sage/guards";
import {
	CollectEmail,
	ConfirmResetPasswordPage,
	ConfirmationPage,
	CreateTeam,
	JoinTeam,
	Login,
	RequestForgotPasswordPage,
	VerifyEmail,
	VerifyEmailForgotPasswordPage
} from "./Components/Auth";
import {
	Accounts,
	ActiveDeals,
	AddCompanyPage,
	Athena,
	CreateAccount,
	Dashboard,
	DealTimeline,
	Decks,
	DecksFavorites,
	DecksRecent,
	DecksShared,
	Docs,
	DocsFavorites,
	DocsRecent,
	DocsShared,
	OrganizationPage,
	PrivateCompany,
	SearchPage,
	SettingsPage,
	TrendingNewsPage,
	VaultHomePage,
	VaultUserPage,
	WorkflowList
} from "./Components/Pages";
import { Chronos } from "./Components/Pages/Chronos";
import { DealRoomRouter } from "./Components/Pages/DealRooms";
import { DealList } from "./Components/Pages/Deals/List";
import { CreateCIMStateProvider, CreateTeaserStateProvider, PickWorkflow } from "./Components/Pages/Workflows";
import { LeadMagnetGuard } from "./Guards/LeadMagnetGuard";

export function Router() {
	const [authState, authDispatch] = useReducer(AuthReducer, InitialAuthState);

	return (
		<AuthContext.Provider value={authState}>
			<BrowserRouter>
				<Routes>
					<Route
						path="app"
						element={<AnalyticsGuard />}
					>
						<Route
							path={"welcome"}
							element={<OnboardingLayoutGuard />}
						>
							<Route
								path="forgot-password"
								element={<RequestForgotPasswordPage />}
							/>
							<Route
								path="forgot-password/verify-email"
								element={<VerifyEmailForgotPasswordPage />}
							/>
							<Route
								path="forgot-password/reset-password"
								element={<ConfirmResetPasswordPage />}
							/>
							<Route
								path="forgot-password/confirmation"
								element={<ConfirmationPage />}
							/>
							<Route
								path={"login"}
								element={<Login />}
							/>
							<Route path={"create-account"}>
								<Route
									path={"email"}
									element={<CollectEmail />}
								/>
								<Route
									path={"verify-email"}
									element={<VerifyEmail />}
								/>
								<Route
									path={"create-team"}
									element={<CreateTeam />}
								/>
								<Route
									path={"join-team"}
									element={<JoinTeam />}
								/>
								<Route
									path={""}
									element={
										<Navigate
											to={"/app/welcome/create-account/email"}
											replace
										/>
									}
								/>
							</Route>
							<Route
								path={""}
								element={
									<Navigate
										to={"/app/welcome/login"}
										replace
									/>
								}
							/>
						</Route>
						<Route
							path={""}
							element={<AuthGuard authDispatch={authDispatch} />}
						>
							<Route
								path={"fs"}
								element={<FsGuard />}
							>
								<Route path={"workflows"}>
									<Route
										path={"list"}
										element={<WorkflowList />}
									/>
									<Route path={"run"}>
										<Route
											path={""}
											element={<PickWorkflow />}
										/>
										<Route path={":workflow_id"}>
											<Route
												path={"cim/*"}
												element={<CreateCIMStateProvider />}
											/>
											<Route
												path={"teaser/*"}
												element={<CreateTeaserStateProvider />}
											/>
										</Route>
									</Route>
								</Route>
								<Route
									path={"athena"}
									element={<AthenaGuard />}
								>
									<Route
										path={":thread_id"}
										element={<Athena />}
									/>
								</Route>
								<Route
									path={"dashboard"}
									element={<Dashboard />}
								/>
								<Route
									path={"vault"}
									element={<VaultGuard />}
								>
									<Route
										path={"user"}
										element={<VaultUserPage />}
									/>
									<Route
										path={"files/:parent_folder_id"}
										element={<VaultHomePage />}
									/>
									<Route
										path={"files"}
										element={<Navigate to={`/app/fs/vault/files/${authState.user?.teamCode}`} />}
									/>
									<Route
										path={""}
										element={<Navigate to={"/app/fs/vault/files"} />}
									/>
								</Route>
								<Route
									path={"deals"}
									element={<DealsGuard />}
								>
									<Route
										path={"active"}
										element={<DealList />}
									/>
									<Route
										path={"active-old"}
										element={<ActiveDeals />}
									/>
									<Route
										path={"timeline"}
										element={<DealTimeline />}
									/>
								</Route>
								<Route
									path={"docs"}
									element={<DocsGuard />}
								>
									<Route
										path={"recent"}
										element={<DocsRecent />}
									/>
									<Route
										path={"shared"}
										element={<DocsShared />}
									/>
									<Route
										path={"favorites"}
										element={<DocsFavorites />}
									/>
								</Route>
								<Route
									path={"decks"}
									element={<DecksGuard />}
								>
									<Route
										path={"recent"}
										element={<DecksRecent />}
									/>
									<Route
										path={"shared"}
										element={<DecksShared />}
									/>
									<Route
										path={"favorites"}
										element={<DecksFavorites />}
									/>
								</Route>
								<Route
									path={"settings"}
									element={<SettingsPage />}
								/>
								<Route
									path={"organization"}
									element={<OrganizationPage />}
								/>
								<Route
									path={"search"}
									element={<SearchPage />}
								/>
								<Route
									path={"add-company"}
									element={<AddCompanyPage />}
								/>
								<Route path={"private-company"}>
									<Route
										path={":uid"}
										element={<PrivateCompany />}
									/>
									<Route
										path={""}
										element={
											<Navigate
												to={"/app/fs/search"}
												replace
											/>
										}
									/>
								</Route>
								<Route
									path={"news"}
									element={<NewsGuard />}
								>
									<Route
										index
										element={<TrendingNewsPage />}
									/>
								</Route>
								<Route
									path={"_admin"}
									element={<AdminGuard />}
								>
									<Route path={"accounts"}>
										<Route
											path={""}
											element={<Accounts />}
										/>
										<Route
											path={"new"}
											element={<CreateAccount />}
										/>
									</Route>
									<Route
										path="chronos"
										element={<Chronos />}
									/>
								</Route>
								<Route
									path={""}
									element={
										<Navigate
											to={"/app/fs/deals/active"}
											replace
										/>
									}
								/>
							</Route>
							<Route path={"workspace"}>
								<Route
									path={":project_id"}
									element={<WorkspaceGuard />}
								>
									<Route
										path={":tab_id"}
										element={<Docs />}
									/>
									<Route
										path={""}
										element={<Docs />}
									/>
								</Route>
								<Route
									path={""}
									element={
										<Navigate
											to={"/app/fs/home"}
											replace
										/>
									}
								/>
							</Route>
							<Route
								path={"decks/:deck_id"}
								element={<Decks />}
							/>
							<Route
								path={"dealroom/*"}
								element={<DealRoomRouter />}
							/>
							<Route
								path={""}
								element={
									<Navigate
										to={"/app/fs"}
										replace
									/>
								}
							/>
						</Route>
						<Route
							path={"trial"}
							element={<LeadMagnetGuard />}
						/>
					</Route>
				</Routes>
			</BrowserRouter>
		</AuthContext.Provider>
	);
}
