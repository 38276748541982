import { DealRoomProjectPageType } from "@sage/types";
import { SidePanelView, useDealRoomProject } from "@sage/state";
import { FaIcon, RibonMenu, RibonSection } from "@sage/shared/core";

export function InsertSection({ title, id }) {
	const { setShowNewElementModal, setShowScreenshotModal, addPage, setBrowseElements, setSidePanelView, setUpdateSplit } =
		useDealRoomProject();
	return (
		<RibonSection
			title={title}
			id={id}
		>
			<RibonMenu>
				<FaIcon
					icon={"layer-group"}
					size={"lg"}
					color={"#00568e"}
					direction="row"
					gap="0.25rem"
					fontSize="0.85rem"
					onClick={() => {
						setBrowseElements(true);
						setSidePanelView(SidePanelView.PageTemplates);
						setUpdateSplit(Math.random() / 10000 + 0.3);
					}}
				>
					Pages
				</FaIcon>
			</RibonMenu>
			<RibonMenu>
				<FaIcon
					icon={"books"}
					size={"lg"}
					color={"#231811"}
					direction="row"
					gap="0.25rem"
					fontSize="0.85rem"
					onClick={() => {
						setBrowseElements(true);
						setSidePanelView(SidePanelView.BrowseElements);
						setUpdateSplit(Math.random() / 10000 + 0.3);
					}}
				>
					Team Elements
				</FaIcon>
			</RibonMenu>
			<RibonMenu>
				<FaIcon
					icon={"books"}
					size={"lg"}
					color={"#231811"}
					direction="row"
					gap="0.25rem"
					fontSize="0.85rem"
					onClick={() => {
						setBrowseElements(true);
						setSidePanelView(SidePanelView.BrowseSageElements);
						setUpdateSplit(Math.random() / 10000 + 0.3);
					}}
				>
					Sage Elements
				</FaIcon>
			</RibonMenu>
			<RibonMenu>
				<FaIcon
					icon={"box-taped"}
					size={"lg"}
					color={"#d29c6b"}
					onClick={() => {
						setShowNewElementModal(true);
					}}
					direction="row"
					gap="0.25rem"
					fontSize="0.85rem"
				>
					New Element
				</FaIcon>
			</RibonMenu>
			<RibonMenu>
				<FaIcon
					icon={"image"}
					size={"lg"}
					color={"#0051a8"}
					secondaryColor={"#d0deef"}
					secondaryOpacity={"1"}
					direction="row"
					gap="0.25rem"
					fontSize="0.85rem"
					onClick={() => {
						setShowScreenshotModal(true);
					}}
				>
					Screenshot
				</FaIcon>
			</RibonMenu>
			<RibonMenu>
				<FaIcon
					icon={"notebook"}
					color={"#282828"}
					secondaryColor={"#d29c6b"}
					secondaryOpacity={"1"}
					size={"lg"}
					direction={"row"}
					gap={"0.25rem"}
					fontSize="0.85rem"
					width="fit-content"
					onClick={() => addPage(DealRoomProjectPageType.CoverPage, 0)}
				>
					Cover Page
				</FaIcon>
			</RibonMenu>
			<RibonMenu>
				<FaIcon
					icon={"book"}
					color={"#282828"}
					size={"lg"}
					direction={"row"}
					gap={"0.25rem"}
					fontSize="0.85rem"
					width="fit-content"
					onClick={() => addPage(DealRoomProjectPageType.CustomPage)}
				>
					New Page
				</FaIcon>
			</RibonMenu>
		</RibonSection>
	);
}
