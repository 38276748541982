import { SidePanelView, useDealRoom, useDealRoomProject } from "@sage/state";
import { FaIcon, RibonMenu, RibonSection } from "@sage/shared/core";

export function ProjectSettingsSection({ title, id }) {
	const { setUploadModalVisible } = useDealRoom();
	const { setSidePanelView, setUpdateSplit, setShowStyleEditor, setBrowseElements, setShowRibbon } = useDealRoomProject();
	return (
		<RibonSection
			title={title}
			id={id}
		>
			<RibonMenu>
				<FaIcon
					icon={"eye-slash"}
					color={"#00435c"}
					size={"lg"}
					direction={"row"}
					gap={"0.25rem"}
					fontSize="0.85rem"
					width="fit-content"
					onClick={() => setShowRibbon(false)}
				>
					Hide Header
				</FaIcon>
			</RibonMenu>
			<RibonMenu>
				<FaIcon
					icon={"cloud-arrow-up"}
					color={"#0051a8"}
					size={"lg"}
					direction={"row"}
					gap={"0.25rem"}
					fontSize="0.85rem"
					width="fit-content"
					onClick={() => setUploadModalVisible(true)}
				>
					Upload Data
				</FaIcon>
			</RibonMenu>
			<RibonMenu>
				<FaIcon
					icon={"print"}
					color={"#272725"}
					secondaryColor={"#e1d2b9"}
					secondaryOpacity={"1"}
					size={"lg"}
					direction={"row"}
					gap={"0.25rem"}
					fontSize="0.85rem"
					width="fit-content"
					onClick={() => window.print()}
				>
					Export
				</FaIcon>
			</RibonMenu>
			<RibonMenu>
				<FaIcon
					icon={"comments"}
					color={"#00435c"}
					size={"lg"}
					direction={"row"}
					gap={"0.25rem"}
					fontSize="0.85rem"
					width="fit-content"
					onClick={() => {
						setSidePanelView(SidePanelView.ProjectComments);
						setUpdateSplit(Math.random() / 10000 + 0.3);
					}}
				>
					Comments
				</FaIcon>
			</RibonMenu>
			<RibonMenu>
				<FaIcon
					icon={"palette"}
					color={"#0051a8"}
					secondaryColor={"#a37b60"}
					secondaryOpacity={"0.5"}
					size={"lg"}
					direction={"row"}
					gap={"0.25rem"}
					fontSize="0.85rem"
					width="fit-content"
					onClick={() => setShowStyleEditor(true)}
				>
					Style
				</FaIcon>
			</RibonMenu>
		</RibonSection>
	);
}
