import { useForm } from "react-hook-form";
import { DealRoomProjectElementType } from "@sage/types";
import { useDealRoom } from "@sage/state";
import { DealRoomService, LlmService } from "@sage/services";
import { Button, Modal, Row, SectionTitle } from "@sage/shared/core";
import { FormField, FormFieldVariant } from "@sage/shared/forms";
import { isNullOrUndefined } from "@sage/utils";

export function CreateElementModal() {
	const { createElement, setCreateElement } = useDealRoom();
	const form = useForm();

	function dismiss() {
		setCreateElement(null);
	}

	async function submit(e) {
		if (
			isNullOrUndefined(e.element_name) ||
			e.element_name?.length === 0 ||
			isNullOrUndefined(e.element_description) ||
			e.element_description?.length === 0
		) {
			if (isNullOrUndefined(e.element_name) || e.element_name?.length === 0) {
				await LlmService.Stream(
					{
						preprompt:
							"Please generate a name for this Element. And element is a saved LLM prompt, please aim for less than 10 words",
						prompt: JSON.stringify(e)
					},
					(e) => form.setValue("element_name", e)
				);
			}
			if (isNullOrUndefined(e.element_description) || e.element_description?.length === 0) {
				await LlmService.Stream(
					{
						preprompt:
							"Please generate a description for this Element. And element is a saved LLM prompt, please aim for less than 30 words",
						prompt: JSON.stringify(e)
					},
					(e) => form.setValue("element_description", e)
				);
			}
			form.handleSubmit(submit)();
		} else {
			const { prompt, search_terms, element_name, element_description, example } = e;
			await DealRoomService.SaveTemplate({
				element_name,
				element_description,
				prompt,
				search_terms: search_terms.map(({ value }) => value).filter((val) => !!val),
				element_type: createElement,
				example
			});
			form.reset({});
			setCreateElement(null);
		}
	}

	return (
		<Modal
			visible={!isNullOrUndefined(createElement)}
			onDismiss={dismiss}
		>
			<SectionTitle caps>Create {createElement} element</SectionTitle>
			<FormField
				control={form.control}
				placeholder={"Element Name..."}
				name="element_name"
				variant={FormFieldVariant.Vertical}
				label="Element Name"
			/>
			<FormField
				control={form.control}
				placeholder={"Element Description..."}
				name="element_description"
				variant={FormFieldVariant.Vertical}
				label="Element Description"
				type="textarea"
			/>
			<FormField
				control={form.control}
				placeholder={"Prompt..."}
				name="prompt"
				type="textarea"
				variant={FormFieldVariant.Vertical}
				label="Prompt"
				description="This prompt will be used to generate the content for your element."
				rows={10}
			/>
			{(createElement === DealRoomProjectElementType.Paragraph || createElement === DealRoomProjectElementType.Bullets) && (
				<FormField
					control={form.control}
					placeholder={"Example..."}
					name="example"
					type="textarea"
					variant={FormFieldVariant.Vertical}
					label="Example"
					description="If you want, you can give an example of the writing style you are looking for"
					rows={5}
					fill
				/>
			)}
			<FormField
				control={form.control}
				name="search_terms"
				placeholder="Search term..."
				type={"list"}
				label="Search Terms"
				description="These do not have to be keywords. You can add anything to this list that might help us find additional relevant information that will help our models write your section."
			/>
			<Row horizontalAlign="right">
				<Button action={form.handleSubmit(submit)}>Save</Button>
			</Row>
		</Modal>
	);
}
