import { useEffect, useRef, useState } from "react";
import { Actor, IDealRoomMessage, IDealRoomThread } from "@sage/types";
import { useAuthState, useDealRoom } from "@sage/state";
import { DealRoomService, LlmService } from "@sage/services";
import { Chat } from "@sage/shared/chat";
import {
	Button,
	ButtonBorderShape,
	ButtonIcon,
	ButtonVariant,
	Col,
	ItemsCol,
	Pad,
	PaneDirection,
	Popover,
	Row,
	Scroll,
	SectionTitle,
	SplitPane
} from "@sage/shared/core";
import { SourceCrawl, SourceFile, Thread, ThreadVariant } from "@sage/shared/dealrooms";
import { Uuid, isNullOrUndefined } from "@sage/utils";
import { usePrompts } from "@sage/prompts";
import "./DealRoomThreads.scss";

export function DealRoomThreads() {
	const authState = useAuthState();
	const { deal_id, dealThreads, phase_id, loadDealThreads, loadPhaseThreads, setDeleteThread, activeThread, setActiveThread, crawls } =
		useDealRoom();

	const prompts = usePrompts();
	const [messages, setMessages] = useState<IDealRoomMessage[]>([]);
	const [titleLlmResponse, setTitleLlmResponse] = useState<string>(null);
	const [loading, setLoading] = useState(false);
	const [sources, setSources] = useState([]);

	async function createThread() {
		const newThread = await DealRoomService.SaveDealRoomThread({ deal_id });
		await loadDealThreads();
		setActiveThread(newThread);
	}

	async function loadSources() {
		if (!isNullOrUndefined(activeThread?.thread_id)) {
			setSources([
				...(await DealRoomService.GetSourcesByElement(activeThread?.thread_id)),
				...(await DealRoomService.GetCrawlSources(activeThread?.thread_id))
			]);
		}
	}

	async function deleteThread(thread: IDealRoomThread) {
		setDeleteThread(thread);
	}

	async function addThread(thread_id: string) {
		await DealRoomService.AddThreadToPhase(thread_id, phase_id);
		await loadDealThreads();
		await loadPhaseThreads();
	}

	const triesRef = useRef(0);

	function generateText(request, cb): Promise<string> {
		if (triesRef.current < 40) {
			triesRef.current = triesRef.current + 1;
			return new Promise(async (resolve) => {
				try {
					const { generated_text } = await LlmService.Stream(request, cb);

					if (generated_text.length === 0) {
						setTimeout(async () => {
							resolve(await generateText(request, cb));
						}, 2000);
					} else {
						resolve(generated_text);
					}
				} catch (e) {
					setTimeout(async () => {
						resolve(await generateText(request, cb));
					}, 2000);
				}
			});
		} else {
			return new Promise((resolve) => resolve('<flag number="1">An Error ocoured, please try again</flag>'));
		}
	}

	async function send(text: string) {
		setLoading(true);
		const newMessageId = Uuid.Nano();
		const firstIdx = messages.length;
		const secondIdx = firstIdx + 1;
		const _messages = [...messages];

		const message = await DealRoomService.SaveMessage({
			thread_id: activeThread.thread_id,
			text,
			actorType: Actor.User,
			actor: authState.user.user_id,
			idx: firstIdx
		});

		_messages.push(message);

		setMessages((m) => [...m, message]);

		let searchResults = (
			await Promise.all([
				DealRoomService.SearchSources(deal_id, text, activeThread.thread_id),
				...crawls.map((crawl) => DealRoomService.SearchWebData(crawl.host, text))
			])
		).flat();

		const sourceChunks = sources.map((s) => s.chunk_id);
		const sourcePages = sources.map((s) => s.page_id);
		searchResults = [
			...sources,
			...searchResults.filter((result) =>
				"chunk_id" in result ? !sourceChunks.includes(result.chunk_id) : !sourcePages.includes(result.page_id)
			)
		]
			.sort((a, b) => b.relevance_score - a.relevance_score)
			.slice(0, 20);

		setSources(searchResults);

		triesRef.current = 0;
		const generated_text = await generateText(
			{
				prompt: text,
				context: JSON.stringify(searchResults),
				preprompt: prompts.conversationalChat(),
				thread: activeThread.thread_id
			},
			(e) => {
				setMessages((m) => {
					return [
						...m.filter((_m) => _m.message_id !== newMessageId),
						{
							createdTimestamp: new Date().getTime(),
							lastModifiedTimestamp: new Date().getTime(),
							idx: secondIdx,
							actor: "Athena",
							actorType: Actor.Assistant,
							message_id: newMessageId,
							text: e,
							thread_id: activeThread.thread_id
						}
					];
				});
			}
		);
		triesRef.current = 0;

		const responseMessage = await DealRoomService.SaveMessage({
			thread_id: activeThread.thread_id,
			text: generated_text,
			actorType: Actor.Assistant,
			actor: "Athena",
			idx: secondIdx
		});
		_messages.push(responseMessage);

		triesRef.current = 0;
		const newTitle = await generateText(
			{
				preprompt: "Please write me a chat thread title for the following conversation. Please aim for less than 10 words.",
				prompt: JSON.stringify(_messages)
			},
			setTitleLlmResponse
		);
		triesRef.current = 0;

		await DealRoomService.UpdateThreadName(activeThread.thread_id, newTitle);

		await loadDealThreads();
		await loadPhaseThreads();
		setLoading(false);
		await Promise.all(
			searchResults
				.filter((result) =>
					"chunk_id" in result ? !sourceChunks.includes(result.chunk_id) : !sourcePages.includes(result.page_id)
				)
				.map((result) => {
					if ("chunk_id" in result) {
						return DealRoomService.CreateElementSource(result);
					} else {
						return DealRoomService.CreateCrawlSource({ ...result, element_id: activeThread.thread_id });
					}
				})
		);
	}

	async function loadMessages() {
		if (!isNullOrUndefined(activeThread?.thread_id)) {
			setMessages(await DealRoomService.GetMessagesByThread(activeThread.thread_id));
		}
	}

	useEffect(() => {
		loadMessages();
		loadSources();
	}, [activeThread]);

	return (
		<div className="__sage-deal-room-threads">
			{!isNullOrUndefined(activeThread) ? (
				<SplitPane
					direction={PaneDirection.Vertical}
					defaultSplit={0.25}
				>
					<Col
						height="100%"
						scroll
					>
						<Pad>
							<Row
								wrap={false}
								verticalAlign="center"
							>
								<Button
									icon={ButtonIcon.MaterialArrowLeft}
									variant={ButtonVariant.IconSecondarySM}
									action={() => setActiveThread(null)}
								/>
								<SectionTitle
									inline
									strong
									size="sm"
								>
									{titleLlmResponse || activeThread.thread_name}
								</SectionTitle>
							</Row>
						</Pad>
						<div className="chat">
							<Chat
								messages={messages}
								hideStyle
								send={send}
								loading={loading}
							/>
						</div>
					</Col>
					<Scroll>
						<Pad>
							<Col
								gap="1rem"
								height={"100%"}
							>
								<SectionTitle
									strong
									size="sm"
								>
									Source Files
								</SectionTitle>
								<Col gap={"1rem"}>
									{sources
										.sort((b, a) => a.relevance_score - b.relevance_score)
										.map((s) =>
											"chunk_id" in s ? (
												<SourceFile
													key={s.chunk_id || s.element_source_id || s.source_id}
													source={s}
												/>
											) : (
												<SourceCrawl
													key={s.source_id}
													crawl={s}
												/>
											)
										)}
								</Col>
							</Col>
						</Pad>
					</Scroll>
				</SplitPane>
			) : (
				<div className="threads-inner">
					<SectionTitle
						strong
						size="sm"
					>
						<Row verticalAlign="center">
							Your Threads
							<Popover>
								These threads are private to you for this deal.
								<br />
								<br />
								You can always attach a thread to this deal phase by clicking the blue button on the thread item.
							</Popover>
						</Row>
					</SectionTitle>
					<div className="new-thread">
						<Button
							icon={ButtonIcon.MaterialAdd}
							variant={ButtonVariant.Secondary}
							borderShape={ButtonBorderShape.Round}
							action={createThread}
						>
							New Thread
						</Button>
					</div>
					<ItemsCol
						items={dealThreads}
						dateCol={"lastModifiedTimestamp"}
						gap={"1rem"}
						render={(thread) => (
							<Thread
								variant={ThreadVariant.DealRoomThread}
								key={thread.thread_id}
								thread={thread}
								deleteThread={() => deleteThread(thread)}
								addThread={addThread}
								select={() => setActiveThread(thread)}
							/>
						)}
					/>
				</div>
			)}
		</div>
	);
}
