import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { byPrefixAndName } from "@awesome.me/kit-17c50395ad/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNullOrUndefined, notNullOrUndefined } from "@sage/utils";
import "./FaIcon.scss";

export function FaIcon({
	color,
	icon,
	size,
	animation,
	children,
	direction,
	tooltip,
	active,
	hideBg,
	border,
	width,
	borderRadius,
	paddingInline,
	padding,
	onClick,
	onMouseDown,
	loop,
	secondaryOpacity,
	secondaryColor,
	gap,
	fontSize
}: {
	color?: string;
	icon: string;
	size?: string;
	animation?: string;
	children?: ReactNode;
	direction?: "row" | "column";
	tooltip?: ReactNode;
	active?: boolean;
	hideBg?: boolean;
	border?: boolean;
	width?: string;
	paddingInline?: string;
	padding?: string;
	borderRadius?: string;
	onClick?: (e?: any) => void;
	onMouseDown?: (e?: any) => void;
	loop?: boolean;
	secondaryOpacity?: string;
	gap?: string;
	fontSize?: string;
	secondaryColor?: string;
}) {
	const [hover, setHover] = useState<boolean>(false);
	const [hoverColor, setHoverColor] = useState<string>(null);
	const [hoverBorderColor, setHoverBorderColor] = useState<string>(null);

	const [cooldown, setCooldown] = useState<boolean>(false);

	function onClickHandle(e) {
		if (!cooldown) {
			setCooldown(true);
			onClick(e);
			setTimeout(() => {
				setCooldown(false);
			}, 500);
		}
	}

	useEffect(() => {
		if (color) {
			const r = parseInt(color.slice(1, 3), 16);
			const g = parseInt(color.slice(3, 5), 16);
			const b = parseInt(color.slice(5, 7), 16);

			setHoverColor(`rgba(${r}, ${g}, ${b}, 0.05)`);
			setHoverBorderColor(`rgba(${r}, ${g}, ${b}, 0.2)`);
		}
	}, [color]);

	return (
		<div
			className={`__sage-fa-icon-container ${border ? "border" : ""} ${direction} ${isNullOrUndefined(onClick) && isNullOrUndefined(onMouseDown) ? "no-handlers" : "handlers"}`}
			style={{ width, borderRadius, paddingInline, padding, gap, fontSize }}
			onMouseOver={() => setHover(true)}
			onMouseOut={() => setHover(false)}
			onClick={(e) => (notNullOrUndefined(onClick) ? onClickHandle(e) : {})}
			onMouseDown={(e) => (notNullOrUndefined(onMouseDown) ? onMouseDown(e) : {})}
		>
			<FontAwesomeIcon
				icon={byPrefixAndName.fad[icon]}
				size={size}
				color={color}
				className={`${(hover || loop) && animation} ${loop ? "loop" : ""}`}
				fixedWidth={direction === "row"}
				style={{ "--fa-secondary-opacity": secondaryOpacity || "0.4", "--fa-secondary-color": secondaryColor || color }}
			/>
			{children}
			{tooltip && (
				<div className={`tooltip ${hover ? "open" : ""}`}>
					<FaIcon
						icon="circle-info"
						color={color}
						hideBg
						direction={"row"}
						size="xl"
					>
						{tooltip}
					</FaIcon>
				</div>
			)}
			{hideBg !== true && (
				<div
					className={`bg ${active ? "active" : ""}`}
					style={{ backgroundColor: hoverColor, border: `solid 1px ${hoverBorderColor}`, borderRadius }}
				/>
			)}
		</div>
	);
}
