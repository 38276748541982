import React from "react";
import { Link } from "react-router-dom";
import { useAuthState, useDealRoomProject } from "@sage/state";
import { Col, Collapsible, FaIcon, Ribon, Row } from "@sage/shared/core";
import { UploadFilesModal } from "../../DealRoomHome/UploadFilesModal";
import "./Header.scss";
import {
	ChartsAndGraphsSection,
	FinancialStatementSection,
	InsertSection,
	ListsSection,
	NarrativeSection,
	ProjectSettingsSection,
	SectionsSection,
	StatusSection,
	TablesSection
} from "./Sections";

export function Header() {
	const { user } = useAuthState();
	const { project_name, updateProjectName, showRibbon, setShowRibbon } = useDealRoomProject();

	return (
		<>
			<div className={`__sage-deal-room-project-header ${showRibbon ? "" : "collapsed"}`}>
				<Collapsible
					visible={showRibbon}
					allowOverflow
				>
					<Col
						height="100%"
						gap="0"
						verticalAlign="bottom"
						flex={"1"}
					>
						<Row
							horizontalAlign="between"
							verticalAlign="center"
						>
							<Row verticalAlign="center">
								<Link to={user.is_lead_magnet ? "." : "../../"}>
									<FaIcon
										size="xl"
										icon="arrow-turn-left"
										color="#2965c6"
										secondaryOpacity="0.75"
										paddingInline="0"
										padding="0.25rem"
									/>
								</Link>
								<input
									className="project-name"
									value={project_name}
									onChange={(e) => updateProjectName(e.target.value)}
									style={{ width: `${Math.max(project_name?.length * 1.3, 12)}ch` }}
								/>
							</Row>
						</Row>
						<Row verticalAlign="bottom">
							<Ribon>
								<InsertSection
									title={"Insert"}
									id={"Section"}
								/>
								<SectionsSection
									title={"Sections"}
									id={"Sections"}
								/>
								<ChartsAndGraphsSection
									title={"Charts"}
									id={"charts-graphs"}
								/>
								<FinancialStatementSection
									title={"Financial Statements"}
									id={"financial-statements"}
								/>
								<TablesSection
									title={"Tables"}
									id={"tables"}
								/>
								<ListsSection
									title={"Lists"}
									id={"lists"}
								/>
								<NarrativeSection
									title={"Narratives"}
									id={"narratives"}
								/>
								<StatusSection
									title={"Status"}
									id={"status"}
								/>
								<ProjectSettingsSection
									title={"Project"}
									id={"project"}
								/>
							</Ribon>
						</Row>
					</Col>
				</Collapsible>
				<UploadFilesModal />
			</div>
			{showRibbon === false && (
				<div
					className="__sage-project-editor-show-header"
					onClick={() => setShowRibbon(true)}
				>
					Show header
				</div>
			)}
		</>
	);
}
