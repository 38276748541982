import { useDealRoom } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { Button, ButtonBorderShape, ButtonIcon, ButtonVariant, ItemsCol, Popover, Row, SectionTitle } from "@sage/shared/core";
import { Thread, ThreadVariant } from "@sage/shared/dealrooms";
import "./DealPhaseThreads.scss";

export function DealPhaseThreads() {
	const { phase_id, phase, deal_id, phaseThreads, loadPhaseThreads, loadDealThreads, setActiveThread } = useDealRoom();

	async function createThread() {
		const newThread = await DealRoomService.SaveDealRoomThread({ deal_id, phase_id });
		await loadPhaseThreads();
		setActiveThread(newThread);
	}

	async function deleteThread(thread_id: string) {
		await DealRoomService.RemoveThreadFromPhase(thread_id);
		await loadPhaseThreads();
		await loadDealThreads();
	}

	return (
		<div className="__sage-deal-room-phase-threads">
			<SectionTitle
				strong
				size="sm"
			>
				<Row verticalAlign="center">
					{phase?.phase_name} Threads
					<Popover>
						These are threads that are attached to this deal phase.
						<br />
						<br />
						You can use this to create threads that your team members can view, and to keep track of useful deal information.
					</Popover>
				</Row>
			</SectionTitle>
			<div className="new-thread">
				<Button
					icon={ButtonIcon.MaterialAdd}
					variant={ButtonVariant.Secondary}
					borderShape={ButtonBorderShape.Round}
					action={createThread}
				>
					New Thread
				</Button>
			</div>
			<ItemsCol
				items={phaseThreads}
				dateCol={"lastModifiedTimestamp"}
				gap={"1rem"}
				render={(thread) => (
					<Thread
						key={thread.thread_id}
						thread={thread}
						deleteThread={deleteThread}
						variant={ThreadVariant.PhaseThread}
						select={() => setActiveThread(thread)}
					/>
				)}
			/>
		</div>
	);
}
