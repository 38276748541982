import React, { ReactNode, useEffect, useRef, useState } from "react";
import { Actor, IMessage } from "@sage/types";
import { Button, ButtonBorderShape, ButtonVariant, FaIcon, LoadingBubble, Row } from "@sage/shared/core";
import { isNullOrUndefined } from "@sage/utils";
import { Message } from "../Message";
import { StyleSelector } from "../StyleSelector";
import "./Chat.scss";

export interface IChatProps {
	messages?: IMessage[];
	inProgressMessage?: string;
	inProgressMessageKey?: string;
	loading?: boolean;
	hideStyle?: boolean;
	send?: (text: string) => void;
	sendQuickAction?: (text: string) => void;
	sendChartQuickAction?: (text: string) => void;
	hideChat?: boolean;
	hideQuickActions?: boolean;
	messageActions?: (message: IMessage) => ReactNode;
	comment?: boolean;
}

export function Chat({
	messages,
	inProgressMessage,
	inProgressMessageKey,
	send,
	loading,
	hideStyle,
	hideChat,
	messageActions,
	hideQuickActions,
	sendQuickAction,
	sendChartQuickAction,
	comment
}: IChatProps) {
	const [showPlaceholder, setShowPlaceholder] = useState<boolean>(true);
	const scrollRef = useRef(null);
	const userInputRef = useRef(null);
	const focusedRef = useRef(false);

	function handleSend() {
		const message = userInputRef.current.textContent;

		if (message.length) {
			userInputRef.current.textContent = "";
			send(message);
			setTimeout(() => {
				updateScroll();
			}, 0);
		}
		if (focusedRef.current === false) {
			setShowPlaceholder(true);
		}
	}

	function handleKeyDown(e) {
		if (e.key === "Enter" && e.shiftKey) {
			e.preventDefault();
			e.stopPropagation();
			handleSend();
		}
	}

	function updateScroll() {
		if (scrollRef.current.lastElementChild) {
			scrollRef.current.lastElementChild?.scrollIntoView({ behavior: "smooth", block: "end" });
		}
	}

	useEffect(() => {
		if (scrollRef.current) {
			setTimeout(() => {
				updateScroll();
			}, 0);
		}
	}, [messages, inProgressMessage, scrollRef.current]);

	useEffect(() => {
		setTimeout(() => {
			updateScroll();
		}, 0);
	}, []);

	return (
		<div className="__sage-chat-container">
			{hideStyle !== true ? <StyleSelector /> : null}
			<div
				className="messages"
				ref={scrollRef}
			>
				{messages
					?.sort((a, b) => a.idx - b.idx)
					.map((message) => (
						<Message
							key={message.message_id}
							message={message}
							messageActions={messageActions}
						/>
					))}
				{inProgressMessage && (
					<Message
						key={inProgressMessageKey}
						message={{
							idx: -1,
							actorType: Actor.Assistant,
							timestamp: new Date().getTime(),
							actor: "Sage",
							message_id: `${messages?.length}-in-progress`,
							text: inProgressMessage,
							sources: []
						}}
						messageActions={messageActions}
						done={false}
					/>
				)}
				{loading && (
					<div className="__sage-chat-window-loading">
						<LoadingBubble />
					</div>
				)}
				{messages.length > 0 && (
					<Row
						paddingInline="0.25rem"
						horizontalAlign="space-evenly"
						gap={"0"}
					>
						<FaIcon
							icon="truck-fast"
							color="#4272a0"
							size="xl"
							onClick={() =>
								isNullOrUndefined(sendQuickAction)
									? send(
											"Rewrite this response more concisely, focusing only on the essential data points and key actionable insights. Remove all unnecessary context and explanations while maintaining the core message"
										)
									: sendQuickAction(
											"Rewrite this response more concisely, focusing only on the essential data points and key actionable insights. Remove all unnecessary context and explanations while maintaining the core message"
										)
							}
						>
							Concise
						</FaIcon>
						<FaIcon
							icon="pen-field"
							color="#4272a0"
							size="xl"
							onClick={() =>
								isNullOrUndefined(sendQuickAction)
									? send(
											"Expand this response with deeper financial analysis. Add relevant market context, supporting data, risk considerations, and step-by-step explanations of complex concepts. Include specific examples and implications for decision-making."
										)
									: sendQuickAction(
											"Expand this response with deeper financial analysis. Add relevant market context, supporting data, risk considerations, and step-by-step explanations of complex concepts. Include specific examples and implications for decision-making."
										)
							}
						>
							Elaborate
						</FaIcon>
						<FaIcon
							icon="heading"
							color="#4272a0"
							size="xl"
							onClick={() =>
								isNullOrUndefined(sendQuickAction)
									? send("Add a title to this element")
									: sendQuickAction("Add a title to this element")
							}
						>
							Title
						</FaIcon>
						{!isNullOrUndefined(sendChartQuickAction) && (
							<FaIcon
								icon="chart-line-up"
								color="#4272a0"
								size="xl"
								tooltip="Create a chart to accompany this element"
								onClick={() =>
									sendChartQuickAction(
										"Create a chart visualization that illustrates the key metrics and trends discussed in the analysis above. The visualization should complement and reinforce the main points of the report section. Use the provided charting syntax to implement the visualization. Please also repeat the previous element in your response so the entire message can be copied"
									)
								}
							>
								Chart
							</FaIcon>
						)}
						<FaIcon
							icon="magnifying-glass-arrows-rotate"
							color="#4272a0"
							size="xl"
							onClick={() =>
								send(
									"Using the updated/new data I just provided, could you revise your previous analysis? Please keep the same general approach but incorporate all the latest information."
								)
							}
						>
							Refresh Data
						</FaIcon>
						<FaIcon
							icon="redo"
							color="#4272a0"
							size="xl"
							onClick={() =>
								isNullOrUndefined(sendQuickAction)
									? send(
											"I liked your previous response, but could you try a different approach or variation? Keep the same general goal but vary the specifics/execution."
										)
									: sendQuickAction(
											"I liked your previous response, but could you try a different approach or variation? Keep the same general goal but vary the specifics/execution."
										)
							}
						>
							Retry
						</FaIcon>
					</Row>
				)}
			</div>
			{hideChat !== true && (
				<div className={"text-input"}>
					<div
						className={`text-input-inner ${showPlaceholder ? (comment ? "comment-placeholder" : "placeholder") : ""}`}
						contentEditable
						ref={userInputRef}
						onFocus={() => {
							focusedRef.current = true;
							setShowPlaceholder(false);
						}}
						onBlur={() => {
							if (userInputRef.current.textContent === "") {
								focusedRef.current = false;
								setShowPlaceholder(true);
							}
						}}
						data-testid="send-llm-message-form-control"
						onKeyDown={handleKeyDown}
					/>
					<Button
						children={"Send"}
						variant={ButtonVariant.Primary}
						borderShape={ButtonBorderShape.Round}
						action={handleSend}
						loading={loading}
						testid="send-llm-message"
					/>
				</div>
			)}
		</div>
	);
}
