import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useOutletContext } from "react-router-dom";
import { WelcomeAction, WelcomeContext } from "@sage/state";
import { UserService } from "@sage/services";
import { ButtonBorderShape, ButtonVariant } from "@sage/shared/core";
import { Form } from "@sage/shared/forms";

export function RequestForgotPasswordPage() {
	const welcomeState = React.useContext(WelcomeContext);
	const { welcomeDispatch } = useOutletContext<{ welcomeDispatch }>();

	const { control, handleSubmit, reset } = useForm();

	const navigate = useNavigate();

	async function formSubmit(e) {
		let user_id = null;
		const userResponse = await UserService.RequestUserPasswordReset({
			email: e.email
		});
		user_id = userResponse.user_id;
		welcomeDispatch({
			type: WelcomeAction.UpdateWelcomeForm,
			payload: {
				...e,
				user_id
			}
		});
		navigate("/app/welcome/forgot-password/verify-email");
	}

	React.useEffect(() => {
		if (welcomeState.welcomeForm) {
			reset(welcomeState.welcomeForm);
		}
	}, [welcomeState.welcomeForm]);

	return (
		<Form
			title={"Forgot Password"}
			description="Please enter your email address. We will send you a verification code to confirm your email address."
			fields={[
				{
					label: "Email",
					placeholder: "e.g. matt@sageai.dev",
					control,
					name: "email",
					required: true
				}
			]}
			actions={[
				{
					variant: ButtonVariant.Primary,
					children: "Next",
					action: handleSubmit(formSubmit),
					borderShape: ButtonBorderShape.Round,
					testid: "navigate-next"
				}
			]}
		/>
	);
}
