import React, { useEffect, useRef, useState } from "react";
import { DealRoomProjectPageType } from "@sage/types";
import { SidePanelView, useAuthState, useDealRoomProject, useDealRoomProjectPage, useProjectTypeName } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { FaIcon, Row } from "@sage/shared/core";
import { isNullOrUndefined } from "@sage/utils";
import { Element } from "../Element";
import { PageIcon } from "../Shared";
import "./Page.scss";

export function Page({ page_id }: { page_id: string }) {
	const pageRef = useRef(null);
	const authState = useAuthState();
	const {
		project_id,
		showStyleEditor,
		setSidePanelView,
		setUpdateSplit,
		primary_color,
		secondary_color,
		font_color,
		setSavePageModalVisible,
		setSavePageId
	} = useDealRoomProject();
	const {
		active,
		project_type,
		elements,
		moveDown,
		moveUp,
		page_name,
		page_index,
		page_layout,
		page_type,
		page_theme,
		remove,
		setPageActive,
		shouldMoveDown,
		shouldMoveUp,
		updatePageName,
		reportHeight
	} = useDealRoomProjectPage(page_id);
	const projectTypeName = useProjectTypeName(project_type);
	const [layout, setLayout] = useState<string>(page_layout);
	const [logoAvailable, setLogoAvailable] = useState<boolean>(false);
	const [logoUrl, setLogoUrl] = useState<string>(null);
	const pageElementsRef = useRef(null);

	function saveTemplate() {
		setSavePageModalVisible(true);
		setSavePageId(page_id);
	}

	async function downloadLogo() {
		const _logo = await DealRoomService.DownloadLogo(project_id);
		setLogoUrl(_logo);
		const res = await fetch(_logo);
		setLogoAvailable(res.status === 200);
	}

	function handleResize() {
		reportHeight(pageRef.current?.getBoundingClientRect()?.height);
	}

	async function updateLayout(layout: string) {
		setLayout(layout);
		await DealRoomService.UpdatePageLayout(page_id, layout);
	}

	useEffect(() => {
		if (pageRef.current) {
			const observer = new ResizeObserver(handleResize);
			observer.observe(pageRef.current);
			return () => {
				try {
					observer.unobserve(pageRef.current);
				} catch (e) {}
			};
		}
	}, [pageRef.current]);

	useEffect(() => {
		downloadLogo();
	}, [showStyleEditor]);

	function autoscale() {
		const bbox = pageElementsRef.current.getBoundingClientRect();
		const pageHeight = 793 - 155;
		if (bbox.height > pageHeight) {
			const resizeFactor = pageHeight / bbox.height;
			pageElementsRef.current.style.scale = `${resizeFactor} ${resizeFactor}`;
		}
	}

	function removeAutoscale() {
		pageElementsRef.current.style.scale = "1 1";
	}

	useEffect(() => {
		window.addEventListener("beforeprint", autoscale);
		window.addEventListener("afterprint", removeAutoscale);
		return () => {
			window.removeEventListener("beforeprint", autoscale);
			window.removeEventListener("afterprint", removeAutoscale);
		};
	}, []);

	useEffect(() => {
		if (pageRef.current && active) {
			pageRef.current.lastElementChild?.scrollIntoView({ behavior: "smooth", block: "end" });
		}
	}, [active]);

	return (
		<div
			className="__sage-project-editor-page-outer"
			ref={pageRef}
			style={{
				transform: shouldMoveDown ? `translateY(${shouldMoveDown}px)` : shouldMoveUp ? `translateY(${-shouldMoveUp}px)` : null,
				transition: shouldMoveDown || shouldMoveUp ? "transform 300ms ease-in-out" : null
			}}
		>
			<div
				className={`__sage-project-editor-page ${active ? "active" : ""} ${page_type === DealRoomProjectPageType.CoverPage ? "cover-page" : ""}`}
				onClick={page_type !== DealRoomProjectPageType.CoverPage && setPageActive}
				//onMouseOver={setPageActive}
			>
				{logoAvailable && (
					<div className="logo">
						<img src={logoUrl} />
					</div>
				)}
				<ThemeStuff
					page_type={page_type}
					page_theme={page_theme}
					primary_color={primary_color}
					secondary_color={secondary_color}
				/>
				<div className={`page-title-container ${page_type === DealRoomProjectPageType.CoverPage ? "cover-page" : ""}`}>
					<input
						className="page-title"
						placeholder="Page Title..."
						value={page_name}
						onChange={(e) => updatePageName(e.target.value)}
						style={{ color: font_color }}
					/>
					{page_type === DealRoomProjectPageType.CoverPage && (
						<div
							className="subtitle"
							style={{ color: font_color }}
						>
							{projectTypeName?.toLowerCase()}
						</div>
					)}
				</div>
				{page_type !== DealRoomProjectPageType.CoverPage && (
					<div
						className={`elements ${layout}`}
						ref={pageElementsRef}
					>
						{elements
							?.sort((a, b) => a.element_idx - b.element_idx)
							.map((el) => (
								<Element
									key={el.element_id}
									element_id={el.element_id}
									page_id={page_id}
								/>
							))}
					</div>
				)}
				{page_type === DealRoomProjectPageType.CoverPage && (
					<div
						className="cover-page-bottom-details"
						style={{ color: font_color }}
					>
						{authState.team.teamName}
						<br />
						{new Date().toLocaleDateString("en-US", { year: "numeric" })}
					</div>
				)}
				{page_type !== DealRoomProjectPageType.CoverPage && (
					<div className="page-bottom-details">
						<Row
							horizontalAlign="between"
							verticalAlign="bottom"
							paddingBottom="0.25rem"
						>
							<div />
							<Row verticalAlign="center">
								<div className="document-type">{projectTypeName}</div>
								<div className="page-number">
									{page_index < 9 ? "0" : ""}
									{page_index + 1}
								</div>
							</Row>
						</Row>
					</div>
				)}
			</div>
			<div className="__sage-project-editor-bottom-buttons">
				<Row
					horizontalAlign="between"
					verticalAlign="center"
					paddingTop="0.25rem"
				>
					<Row verticalAlign="center">
						<FaIcon
							icon={"up"}
							color={"#00435c"}
							size={"lg"}
							onClick={moveUp}
						></FaIcon>
						<FaIcon
							icon={"down"}
							color={"#00435c"}
							size={"lg"}
							onClick={moveDown}
						></FaIcon>
					</Row>
					<Row>
						<FaIcon
							icon={"grid-2"}
							color={"#00435c"}
							size={"lg"}
							onClick={() => updateLayout("two-cols")}
							active={layout === "two-cols"}
						></FaIcon>
						<FaIcon
							icon={"diagram-cells"}
							color={"#00435c"}
							size={"lg"}
							onClick={() => updateLayout("one-col")}
							active={layout === "one-col"}
						></FaIcon>
						<FaIcon
							icon={"gallery-thumbnails"}
							color={"#00435c"}
							size={"lg"}
							onClick={() => updateLayout("gallery")}
							active={layout === "gallery"}
						></FaIcon>
						<FaIcon
							icon={"comments"}
							color={"#00435c"}
							size={"lg"}
							onClick={() => {
								setSidePanelView(SidePanelView.PageComments);
								setPageActive();
								setUpdateSplit(Math.random() / 10000 + 0.3);
							}}
						/>
						{elements?.length > 0 && (
							<FaIcon
								icon={"save"}
								color={"#5da035"}
								size={"lg"}
								onClick={saveTemplate}
							></FaIcon>
						)}
						<FaIcon
							icon={"trash-can"}
							color={"#a80300"}
							animation={"fa-shake"}
							size={"lg"}
							onClick={remove}
						></FaIcon>
					</Row>
				</Row>
				<AddPageFollower page_idx={page_index} />
			</div>
		</div>
	);
}

function AddPageFollower({ page_idx }) {
	const { addPage } = useDealRoomProject();
	const [hover, setHover] = useState<boolean>(false);

	return (
		<div
			className="__sage-add-page-follower"
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<Row
				verticalAlign="center"
				horizontalAlign="start"
			>
				<FaIcon
					icon="layer-plus"
					size="xl"
					color="#5700a8"
				></FaIcon>
				<div className={`__sage-add-page-follower-inner ${hover ? "open" : "closed"}`}>
					<Row>
						<PageIcon
							pageType={DealRoomProjectPageType.CustomPage}
							onClick={() => addPage(DealRoomProjectPageType.CustomPage, page_idx + 1)}
						>
							New Page
						</PageIcon>
					</Row>
				</div>
			</Row>
		</div>
	);
}

function ThemeStuff({ page_type, page_theme, primary_color, secondary_color }) {
	if (page_type === DealRoomProjectPageType.CoverPage) {
		if (page_theme === "default" || isNullOrUndefined(page_theme)) {
			return (
				<DefaultThemeCoverPage
					primary_color={primary_color}
					secondary_color={secondary_color}
				/>
			);
		} else if (page_theme === "theme_2") {
			return (
				<CoverPageTheme2
					primary_color={primary_color}
					secondary_color={secondary_color}
				/>
			);
		}
	} else {
		if (page_theme === "default" || isNullOrUndefined(page_theme)) {
			return (
				<DefaultTheme
					primary_color={primary_color}
					secondary_color={secondary_color}
				/>
			);
		} else if (page_theme === "theme_2") {
			return (
				<Theme2
					primary_color={primary_color}
					secondary_color={secondary_color}
				/>
			);
		}
	}
}

function DefaultTheme({ primary_color, secondary_color }) {
	return (
		<div
			className="style"
			style={{ display: "" }}
		>
			<div
				className="style-1"
				style={{ backgroundColor: primary_color }}
			/>
			<div
				className="style-2"
				style={{ backgroundColor: secondary_color }}
			/>
			<div className="style-3" />
			<div
				className="style-1-bottom"
				style={{ backgroundColor: primary_color }}
			/>
			<div
				className="style-2-bottom"
				style={{ backgroundColor: secondary_color }}
			/>
			<div className="style-3-bottom" />
		</div>
	);
}

function DefaultThemeCoverPage({ primary_color, secondary_color }) {
	return (
		<div
			className="style"
			style={{ display: "" }}
		>
			<div
				className="style-cover-page-2"
				style={{ backgroundColor: secondary_color }}
			/>
			<div
				className="style-cover-page-1"
				style={{ backgroundColor: primary_color }}
			/>
			<div
				className="style-cover-page-1-bottom"
				style={{ backgroundColor: primary_color }}
			/>
			<div
				className="style-cover-page-2-bottom"
				style={{ backgroundColor: secondary_color }}
			/>
		</div>
	);
}

function CoverPageTheme2({ primary_color, secondary_color }) {
	return (
		<div
			className="style"
			style={{ display: "" }}
		>
			<div
				className="theme-2-style-cover-page-2"
				style={{ backgroundColor: primary_color }}
			/>
			<div
				className="theme-2-style-cover-page-1"
				style={{ backgroundColor: secondary_color }}
			/>
			<div
				className="theme-2-style-cover-page-1-bottom"
				style={{ backgroundColor: primary_color }}
			/>
			<div
				className="theme-2-style-cover-page-2-bottom"
				style={{ backgroundColor: secondary_color }}
			/>
		</div>
	);
}

function Theme2({ primary_color, secondary_color }) {
	return (
		<div
			className="style"
			style={{ display: "" }}
		>
			<div
				className="theme-2-style-1-bottom"
				style={{ backgroundColor: primary_color }}
			/>
			<div
				className="theme-2-style-2-bottom"
				style={{ backgroundColor: secondary_color }}
			/>
		</div>
	);
}
