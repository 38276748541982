import { DealRoomProjectElementType } from "@sage/types";
import { dealRoomProjectElementIcon, useDealRoomProject } from "@sage/state";
import { DealRoomService } from "@sage/services";
import { FaIcon, RibonMenu, RibonSection } from "@sage/shared/core";
import { useStages } from "@sage/utils";

export function SectionsSection({ title, id }) {
	const { addElement } = useDealRoomProject();

	async function addSageElement(element_type, element_id: string) {
		addElement(element_type, element_id);
		await DealRoomService.EmitSageTemplateUse(element_id);
	}

	return (
		<RibonSection
			title={title}
			id={id}
		>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "CHYCKXJUCHNHXVWQ"
									})
								);
							}}
						>
							Company Legal Structure
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "Q6CKJCJVDRAS"
									})
								);
							}}
						>
							Business Description
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "JAJJTXVREACNVJVS"
									})
								);
							}}
						>
							Financial Performance Summary
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "DD7GF6C6DRKC"
									})
								);
							}}
						>
							Financial Performance Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "EQKWFE4DRMKM"
									})
								);
							}}
						>
							Product Portfolio
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "BFWLBDPKPQAG"
									})
								);
							}}
						>
							Market Position
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "ECDAMFFKBZHMSATW"
									})
								);
							}}
						>
							Ownership Structure
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "RMPLMHWCNHEVPKPD"
									})
								);
							}}
						>
							Operational Infrastructure
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "JPTPGDVLURGXNTAS"
									})
								);
							}}
						>
							Historical & Projected Income Statement
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "CMGXNKCGMLFLVETD"
									})
								);
							}}
						>
							Customer Relationships
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "PPECDADMFEQBSGZX"
									})
								);
							}}
						>
							Operating Model
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"pen-fancy"}
					size={"lg"}
					color={"#4d4d4d"}
					secondaryColor={"#ecc39c"}
					secondaryOpacity="0.9"
					direction="row"
					gap="0.25rem"
					fontSize="0.85rem"
				>
					Executive Summary
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										beta: "YVBN6QCJ6XUJ",
										prod: "GLNZNAKQNSRQHJVY"
									})
								);
							}}
						>
							Company History
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "ZXFXNEFVXMLSGLAE"
									})
								);
							}}
						>
							Operational History
						</FaIcon>
						<FaIcon
							icon={"pie-chart"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										beta: "TSN6TXHELYAR",
										prod: "ATEN5C96VDZH"
									})
								);
							}}
						>
							Revenue Distribution Chart
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "MWKLPHPDFUYTXJLP"
									})
								);
							}}
						>
							Market Growth Narrative
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "TUCFDLFBQZGALKYF"
									})
								);
							}}
						>
							Customer Segment Profiles
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										beta: "696T4Q-LYB4M",
										prod: "QZSVYSTDDBMMTULC"
									})
								);
							}}
						>
							Market Overview
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "ASAULHJNWCPKNEAZ"
									})
								);
							}}
						>
							Market Size Metrics
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										beta: "H46BWTS543PU",
										prod: "GLNSRC7Y9P2L"
									})
								);
							}}
						>
							Growth Drivers
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"building-memo"}
					size={"lg"}
					color={"#0051a850"}
					secondaryColor={"#4d4d4d"}
					secondaryOpacity={"1"}
					direction="row"
					gap="0.25rem"
					fontSize="0.85rem"
				>
					Business Overview
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={"pie-chart"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "HA9XV9BU2FGE"
									})
								);
							}}
						>
							Product Revenue Distribution
						</FaIcon>
						<FaIcon
							icon={"chart-column"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										beta: "H4Q33K-GKXWU",
										prod: "3H9DYJ2DU9S3"
									})
								);
							}}
						>
							Historical Sales Growth
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "PHJRXDYBZDXWAGWB"
									})
								);
							}}
						>
							Products & Services History
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										beta: "RYVNRZ7JJQAV",
										prod: "SDMKAPNYAZYHGQEY"
									})
								);
							}}
						>
							Product Lines
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "WHJAXMXHBGUPLNMT"
									})
								);
							}}
						>
							Pricing Analysis
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "UUSYRSZCGSXVACAF"
									})
								);
							}}
						>
							Margin Profile Analysis
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"boxes-stacked"}
					size={"lg"}
					color={"#d29c6b"}
					secondaryColor={"#dad3cb"}
					direction="row"
					gap="0.25rem"
					fontSize="0.85rem"
				>
					Products & Services
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										beta: "Q9RD9AQWF7S7",
										prod: "P7U8-V-2WJ35"
									})
								);
							}}
						>
							Customer Relationships
						</FaIcon>
						<FaIcon
							icon={"pie-chart"}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Chart).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Chart,
									useStages({
										prod: "F7CNLCDQDW5W"
									})
								);
							}}
						>
							Customer Type Distribution
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										beta: "HDM955Y2FE-E",
										prod: "82SJV6PD69P6"
									})
								);
							}}
						>
							Key Customers
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "YHSNSYFZZSPYFZCT"
									})
								);
							}}
						>
							Sales Channels
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "DTBCSCAHVSSWGAZG"
									})
								);
							}}
						>
							Sales Team Overview
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "RPXWPXYDDTRBZTCH"
									})
								);
							}}
						>
							Sales Cycle
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"megaphone"}
					size={"lg"}
					color={"#309048"}
					direction="row"
					gap="0.25rem"
					fontSize="0.85rem"
				>
					Sales & Marketing
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "WNSFGLZPJQCEALTR"
									})
								);
							}}
						>
							Production Process
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "RTDDBUSYMHKDVWWQ"
									})
								);
							}}
						>
							Manufacturing Capabilities
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "TKYHKDDDEMXCAYKZ"
									})
								);
							}}
						>
							Product Development Process
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "KULDELMMQKVGBAMR"
									})
								);
							}}
						>
							Top Suppliers
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "WLNPPYPSQPUWUAPT"
									})
								);
							}}
						>
							Regulatory Compliance
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "QZLLDAUZYPWZNMUU"
									})
								);
							}}
						>
							Intellectual Property
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "DGTVYBFMKAYESRAX"
									})
								);
							}}
						>
							Environmental & Safety
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "XVSBHYMKQBVYKQFW"
									})
								);
							}}
						>
							Legal Proceedings
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"network-wired"}
					size={"lg"}
					color={"#0063b1"}
					secondaryColor="#000000"
					direction="row"
					gap="0.25rem"
					fontSize="0.85rem"
				>
					Operations
				</FaIcon>
			</RibonMenu>
			<RibonMenu
				options={
					<>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Bullets).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Bullets,
									useStages({
										prod: "WUQNXSPTQAVTFPLF"
									})
								);
							}}
						>
							Management Team
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "MQDCFPTLNLHJKSPP"
									})
								);
							}}
						>
							Functional Departments
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Paragraph).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Paragraph,
									useStages({
										prod: "FYUGNNWGTXVUPDES"
									})
								);
							}}
						>
							Executive Team Bios
						</FaIcon>
						<FaIcon
							icon={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).icon}
							color={dealRoomProjectElementIcon(DealRoomProjectElementType.Table).color}
							size={"2x"}
							direction={"row"}
							onClick={() => {
								addSageElement(
									DealRoomProjectElementType.Table,
									useStages({
										prod: "DZFVYXEWADLNTTGH"
									})
								);
							}}
						>
							Organizational Structure
						</FaIcon>
					</>
				}
			>
				<FaIcon
					icon={"user-tie"}
					size={"lg"}
					color={"#4d4d4d"}
					secondaryColor={"#daac9e"}
					secondaryOpacity="0.9"
					direction="row"
					gap="0.25rem"
					fontSize="0.85rem"
				>
					Management
				</FaIcon>
			</RibonMenu>
		</RibonSection>
	);
}
